import React, { useEffect, useState, useRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import ReactToPrint from "react-to-print";
import { Row, Col, Dropdown, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/react";

/** Elements */
import { Table } from "../../components/table/Table";
import ContentTable from "../../components/table/ContentTable";
import InputFilter from "../../components/table/InputFilter";
import DateRangeFilter from "../../components/table/DateRangeFilter";
import * as moment from "moment";
import ExportToExcel from "../../components/table/ExportToExcel";
import SelectFilter from "../../components/table/SelectFilter";
import { thousandsFormat } from "../../components/global/GlobalTools";
import ContentSolid from "../../components/global/ContentSolid";
import { LoadingTable } from "../../components/lazyLoading/LazyLoading";
import { MainContext } from "../../App";
import { goToHref } from "../../components/global/GlobalTools";

/** Services */
import { GetIncome } from "../../api/PaymentsReport";
//Custom styles section
const customStyles = {
  textDangerBold: {
    color: "#ea2c54",
    fontWeight: "bold",
    fontSize: "small",
  },
  textGrayBold: {
    color: "#c8cbcc",
    fontWeight: "bold",
    fontSize: "small",
  },
};

const IncomeView = () => {
  const componentRef = useRef(); //Create the reference for Printing
  const { permissions } = useContext(MainContext);
  const [t] = useTranslation(["reports", "global"]); //In the array that is passed as an argument in useTranslation, the translations are loaded per module
  const [loading, setLoading] = useState(true);
  const [exportData, setExportData] = useState([]);
  const [printExport, setPrintExport] = useState(true);
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [showEditColumns, setShowEditColumns] = useState(false);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [filters, setFilters] = useState([]);
  const [selectAllRows, setSelectAllRows] = useState({
    switch: false,
    value: false,
  });
  const [isAllRowsSelected, setIsAllRowsSelected] = useState(false);
  const [requestFilter, setRequestFilter] = useState({});
  const [error, setError] = useState("");
  const [dataTable, setDataTable] = useState([]);
  const [loadingView, setLoadingView] = useState(true);
  const [count, setCount] = useState(0);

  const columns = React.useMemo(
    () => [
      {
        Header: t("headerTable.internalEnrollment"),
        accessor: "student_unique_id",
        Filter: (props) => <InputFilter {...props} />,
        minWidth: dataTable.length && count != 0 ? 110 : 130,
        width: 140,
        maxWidth: 150,
      },
      {
        Header: t("headerTable.name"),
        accessor: "student_name",
        Filter: (props) => <InputFilter {...props} />,
        minWidth: 80,
        width: 150,
        maxWidth: 200,
      },
      {
        Header: t("filterSelectSchoolCycle.schoolCycle"),
        accessor: "school_cycle",
        Filter: SelectFilter,
        minWidth: 80,
        width: 130,
        maxWidth: 150,
      },
      {
        Header: t("headerTable.schoolLevel"),
        accessor: "school_level",
        Filter: SelectFilter, //Filter: InputFilter | SelectFilter | DateRangeFilter (InputFilter is the default filter)
        minWidth: 70,
        width: 80,
        maxWidth: 90,
      },
      {
        Header: t("headerTable.grade"),
        accessor: "grade_group_grade_level",
        Filter: SelectFilter,
        minWidth: 50,
        width: 70,
        maxWidth: 90,
      },
      {
        Header: t("headerTable.date"),
        accessor: "createDate",
        Filter: (props) => (
          <DateRangeFilter
            getByDateRange={getIncomeFromPayments}
            lastNDays={{
              start_date: moment().subtract(30, "day").startOf("day").unix(),
              end_date: moment().endOf("day").unix(),
              label: t("status.lastThirtyDay"),
              selectedByDefault: true,
            }}
            {...props}
          />
        ),
        filter: "dateBetween",
        minWidth: 80,
        width: 100,
        maxWidth: 140,
      },
      {
        Header: t("headerTable.transaction"),
        accessor: "confirmation",
        Filter: (props) => <InputFilter {...props} />,
        minWidth: 110,
        width: 130,
        maxWidth: 200,
      },
      {
        Header: t("headerTable.paymentMethod"),
        accessor: "paymentMethod",
        Filter: (props) => <SelectFilter {...props} />,
        minWidth: 70,
        width: 120,
        maxWidth: 140,
      },
      {
        Header: t("headerTable.cardType"),
        accessor: "cardType",
        Filter: (props) => <SelectFilter {...props} />,
        minWidth: 70,
        width: 120,
        maxWidth: 140,
      },
      {
        Header: t("headerTable.concept"),
        accessor: "concept_name",
        Filter: SelectFilter,
        minWidth: 80,
        width: 120,
        maxWidth: 150,
      },
      {
        Header: t("headerTable.subconcept"),
        accessor: "subconcept_name",
        Filter: SelectFilter,
        minWidth: 80,
        width: 130,
        maxWidth: 150,
      },
      {
        Header: t("headerTable.charge"),
        accessor: "charge",
        Summation: (info) => {
          // Calculate summation of amount if rows change
          const totalPayment = React.useMemo(
            () =>
              info.rows.reduce(
                (sum, row) => parseFloat(row.original.charge || 0) + sum,
                0
              ),
            [info.rows]
          );
          return `$ ${thousandsFormat(totalPayment)}`;
        },
        propertySummation: "charge",
        minWidth: 80,
        width: 120,
        maxWidth: 150,
      },
      {
        Header: t("headerTable.surcharge"),
        accessor: "surcharge",
        Summation: (info) => {
          // Calculate summation of amount if rows change
          const totalSurcharge = React.useMemo(
            () =>
              info.rows.reduce(
                (sum, row) => parseFloat(row.original.surcharge || 0) + sum,
                0
              ),
            [info.rows]
          );
          return `$ ${thousandsFormat(totalSurcharge)}`;
        },
        propertySummation: "surcharge",
        minWidth: 80,
        width: 90,
        maxWidth: 100,
      },
      {
        Header: t("headerTable.payment"),
        accessor: "total_payment",
        Summation: (info) => {
          // Calculate summation of amount if rows change
          const total = React.useMemo(
            () =>
              info.rows.reduce(
                (sum, row) => parseFloat(row.original.total_payment || 0) + sum,
                0
              ),
            [info.rows]
          );
          return `$ ${thousandsFormat(total)}`;
        },
        propertySummation: "total_payment",
        minWidth: 80,
        width: 90,
        maxWidth: 100,
      },
      {
        Header: t("headerTable.onlinePayment"),
        accessor: "onlinePayment",
        Filter: (props) => <SelectFilter {...props} />,
        minWidth: 110,
        width: 120,
        maxWidth: 130,
      },
      {
        Header: "RFC",
        accessor: "RFC",
        Filter: (props) => <InputFilter {...props} />,
        minWidth: 80,
        width: 110,
        maxWidth: 120,
      },
      {
        Header: t("headerTable.fiscalFolio"),
        accessor: "fiscalFolio",
        Filter: (props) => <InputFilter {...props} />,
        minWidth: 70,
        width: 250,
        maxWidth: 300,
      },
      {
        Header: t("headerTable.sequentialInvoiceID"),
        accessor: "sequential_folio",
        Filter: (props) => <InputFilter {...props} />,
        minWidth: 130,
        width: 150,
        maxWidth: 180,
      },
      {
        Header: t("headerTable.invoiceCreation"),
        accessor: "invoiceDate",
        Filter: (props) => (
          <DateRangeFilter
            getByDateRange={getIncomeFromPayments}
            lastNDays={{
              start_date: moment().subtract(30, "day").startOf("day").unix(),
              end_date: moment().endOf("day").unix(),
              label: t("status.lastThirtyDay"),
            }}
            optionNoSelection={true}
            {...props}
          />
        ),
        filter: "dateBetween",
        minWidth: 130,
        width: 150,
        maxWidth: 180,
      },
    ],
    [loading]
  );

  /**
   * Description: Function to get income from payments
   * @param {Object} event Contains the value of the selected filter
   * @param {Object} [column=false] Contains the object of the filter/column origin
   */
  const getIncomeFromPayments = (event, column = false) => {
    let filters = requestFilter;
    const accessorColumn = column.id;
    // To manage where the filters come from to make the request.
    switch (accessorColumn) {
      case "invoiceDate":
        if (event.start_date) {
          filters.start_invoice_date = event.start_date;
          filters.end_invoice_date = event.end_date;
        } else {
          delete filters.start_invoice_date;
          delete filters.end_invoice_date;
        }
        break;
      case "createDate":
        if (event.start_date) {
          filters.start_date = event.start_date;
          filters.end_date = event.end_date;
        } else {
          delete filters.start_date;
          delete filters.end_date;
        }
        break;
      default:
        break;
    }
    // Save filters
    setRequestFilter(filters);
    // Enable loading
    setLoading(true);
    // Get income according the filters
    GetIncome(filters).then((result) => {
      if (result && result.description) {
        setError(result.description);
        Sentry.captureException(Error(JSON.stringify(result)));
      } else {
        let data = result.data.data;
        //filter the existing element
        data = data.filter((element) => element != null);
        //set data for table
        data.forEach((income) => {
          const method =
            income.payment_method && income.payment_method.toLowerCase();
          const cardType = income.card_type && income.card_type.toLowerCase();
          income.id = income.payment_id;
          income.index = income.payment_id;
          //set higher level if exist in school level
          income.program_level
            ? (income.school_level = income.program_level)
            : null;
          income.onlinePayment =
            income.online_payment == "1" ? t("global:yes") : t("global:no");
          income.RFC = income.RFC || "";
          income.fiscalFolio = income.fiscal_folio || "";
          // Format create income date
          let dateMoment = moment.unix(income.create_date);
          income.createDate = dateMoment.format("DD/MM/YYYY");
          // Format create invoice date
          income.invoiceDate = income.create_invoice_date
            ? moment.unix(income.create_invoice_date).format("DD/MM/YYYY")
            : "";
          income.paymentMethod = method ? t(`paymentMethods.${method}`) : "";
          income.cardType = cardType && t(`cardType.${cardType}`);
        });
        setDataTable(data);
      }
      setLoading(false);
      setLoadingView(false);
    });
  };

  //Saves the data for the table in memory, and is not updated unless useMemo identifies a change in the data
  const data = React.useMemo(() => dataTable, [loading]);

  /**
   * Used to clean selected filters
   */
  const cleanRequestFilters = () => {
    let filters = {};
    getIncomeFromPayments(filters);
  };

  //Options to export to excel
  const exportOptions = {
    openAsDownload: true,
    format: "xlsx", //'xlsx' or 'xls' or 'csv'
    filename: t("Income"),
  };

  /**Initial loading */
  useEffect(() => {
    Sentry.setTag("section", "Income");
    let dataFilter = JSON.parse(localStorage.getItem("filterIncome"));
    let dataHiddenColumns = JSON.parse(
      localStorage.getItem("hiddenColumnsIncome")
    );
    if (dataFilter) {
      setFilters(dataFilter);
      localStorage.removeItem("filterIncome");
    }
    if (dataHiddenColumns) {
      setHiddenColumns(dataHiddenColumns);
      localStorage.removeItem("hiddenColumnsIncome");
    }
    //It is used for when there is a date filter in the applied localStorage, if the default date is not applied
    if (dataFilter && dataFilter[0].id === "date") {
      let obj = {
        end_date: dataFilter[0].value.endDate,
        start_date: dataFilter[0].value.startDate,
      };
      getIncomeFromPayments(obj, dataFilter[0]);
    } else {
      let requestFilters = {
        start_date: moment().subtract(30, "day").startOf("day").unix(),
        end_date: moment().endOf("day").unix(),
      };
      getIncomeFromPayments(requestFilters, { id: "createDate" });
    }
  }, []);

  /*
   *Purpose: To block the invoice button when the check is selected and you see if you have invoices or have not yet invoiced.
   */
  useEffect(() => {
    let selectAll = {
      switch: selectAllRows.switch,
      value: isAllRowsSelected,
    };
    setSelectAllRows(selectAll);
    let value = false;
    selectedRowIds.forEach((incomeSelected) => {
      let selectedId = incomeSelected.original.id;
      let income = data.find((element) => element.id == selectedId);
      if (income != -1) {
        value = !value ? income.has_invoice === "0" : value;
      }
    });
  }, [selectedRowIds]);

  return permissions.payments_incomes.access ? (
    <div>
      <Row style={{ marginBottom: "20px" }}>
        <Col xs={12} style={{ position: "relative" }}>
          <Button
            variant="outline-secondary"
            onClick={() => {
              let selectAll = {
                switch: !selectAllRows.switch,
                value: !selectAllRows.value,
              };
              setSelectAllRows(selectAll);
            }}
          >
            {!isAllRowsSelected
              ? t("global:buttons.selectAll")
              : t("global:buttons.unselectAll")}
          </Button>
          {/* Total counter of records and selected records in Income table */}
          {selectedRowIds.length ? (
            selectedRowIds.length == 1 ? (
              <p className="ms-3 d-inline" style={customStyles.textDangerBold}>
                {t("table:textTableViews.selectedRecord", {
                  countSelected: selectedRowIds.length,
                })}
              </p>
            ) : (
              <p className="ms-3 d-inline" style={customStyles.textDangerBold}>
                {t("table:textTableViews.selectedRecords", {
                  countSelected: selectedRowIds.length,
                })}
              </p>
            )
          ) : (
            <p className="ms-3 d-inline" style={customStyles.textGrayBold}>
              {t("table:textTableViews.totalRecords", {
                count: count,
              })}
            </p>
          )}
          <div className="float-end">
            <Dropdown className="d-md-inline-block me-2">
              <Dropdown.Toggle
                id="downloadOption"
                variant="outline-secondary"
                disabled={printExport}
              >
                <i className="bi bi-download"></i>
                {t("global:buttons.download")}
              </Dropdown.Toggle>

              <Dropdown.Menu disabled={printExport}>
                <ReactToPrint
                  trigger={() => (
                    <Dropdown.Item>
                      {t("actionOptions.downloadPDF")}
                    </Dropdown.Item>
                  )}
                  content={() => componentRef.current}
                />
                <ExportToExcel
                  exportData={exportData}
                  exportOptions={exportOptions}
                  printExport={printExport}
                  typeElement="dropdownItem"
                />
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Col>
      </Row>
      {loadingView ? (
        <ContentSolid style={{ marginTop: "20px" }}>
          <LoadingTable />
        </ContentSolid>
      ) : (
        <>
          {error}
          <ContentTable startColumFilter={1} lastColumFilter={8}>
            <Table
              ref={componentRef}
              columns={columns}
              data={data}
              loading={loading}
              rowSelect={true}
              setExportData={setExportData}
              setPrintExport={setPrintExport}
              hiddenColumns={hiddenColumns} //Indicates the columns that will be hidden by default (The array contains the "accessors" of the columns)
              showEditColumns={showEditColumns} //Flag for the modal "Edit List Columns"
              setShowEditColumns={setShowEditColumns} //Toggle function of the "Edit List Columns" modal
              setSelectedRowIds={setSelectedRowIds}
              filters={filters}
              cleanRequestFilters={cleanRequestFilters}
              selectAllRows={selectAllRows}
              setIsAllRowsSelected={setIsAllRowsSelected}
              summation={true} //Indicates if there will be a row of sums summation={true} //Indicates if there will be a row of sums
              setRowsDisplayed={setCount}
            />
          </ContentTable>
        </>
      )}
    </div>
  ) : (
    goToHref("/landing_page")
  );
};

export default IncomeView;

IncomeView.propTypes = {
  cell: PropTypes.Object,
};
