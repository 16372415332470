import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Container,
  Row,
  Col,
  Button,
  Dropdown,
  OverlayTrigger,
  Tooltip,
  Badge,
  ButtonGroup,
} from "react-bootstrap";
import * as Sentry from "@sentry/react";
import moment from "moment";

/*Services */
import { GetRelatives, DeleteRelatives } from "../../api/relatives";
import { UserReviewsPost } from "../../api/Users";

/** Elements */
import ReactToPrint from "react-to-print";
import { Table } from "../../components/table/Table";
import InputFilter from "../../components/table/InputFilter";
import SelectFilter from "../../components/table/SelectFilter";
import ContentTable from "../../components/table/ContentTable";
import {
  getUrlParamValue,
  goToHref,
} from "../../components/global/GlobalTools";
import ContentSolid from "../../components/global/ContentSolid";
import ExportToExcel from "../../components/table/ExportToExcel";
import SchoolCycle from "../../components/global/form/SchoolCycle";
import { LoadingTable } from "../../components/lazyLoading/LazyLoading";
import ModalCreateRelative from "./modals/ModalCreateRelative";
import ModalSuccess from "../../components/global/modals/ModalSuccess";
import ModalNonPremiumReviews from "../../components/staff/ModalNonPremiumReviews";
import ModalConfirmRequestReview from "../../components/staff/ModalConfirmRequestReview";

const customStyles = {
  textGrayBold: {
    color: "#c8cbcc",
    fontWeight: "bold",
    fontSize: "small",
  },
  textDangerBold: {
    color: "#ea2c54",
    fontWeight: "bold",
    fontSize: "small",
  },
};

const prefix = process.env.REACT_APP_PREFIX;

// Get the last cycle saved
let cycleStringify = localStorage.getItem("filterCycleRelatives");

// Save cycle
const obtainFilterCycle = () => {
  let cycle = null;
  const currentOrganizationId = localStorage.getItem(
    `cmOrganizationID${prefix}`
  );
  // Parse last cycle saved
  const preselectedCycle = cycleStringify && JSON.parse(cycleStringify);
  // Evaluates if the last saved cycle matches the current organization id
  if (preselectedCycle?.organization_id == currentOrganizationId) {
    cycle = preselectedCycle;
  }
  // clean the filter in local storage
  localStorage.removeItem("filterCycleRelatives");
  return cycle;
};

/**Get param to open create Relative Modal */
const createRelative = getUrlParamValue("createRelative");
const RelativeView = () => {
  const componentRef = useRef();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [dataTable, setDataTable] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [t] = useTranslation(["relatives", "global", "table"]);
  const [printExport, setPrintExport] = useState(true);
  const [loadingView, setLoadingView] = useState(true);
  const [selectAllRows, setSelectAllRows] = useState({
    switch: false,
    value: false,
  });
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [isAllRowsSelected, setIsAllRowsSelected] = useState(false);
  const [relativesSelectedIds, setRelativesSelectedIds] = useState([]);
  const [showModalCreateReleative, setShowModalCreateReleative] =
    useState(createRelative);
  const [filterCycleId, setFilteCycleId] = useState(false);
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [hasReviewsAnswered, setHasReviewsAnswered] = useState(false);
  const [showModalNonPremiumReviews, setShowModalNonPremiumReviews] =
    useState(false);
  const [showModalConfirmRequestReview, setShowModalConfirmRequestReview] =
    useState(false);
  const [showModalSuccessRequestReviews, setShowModalSuccessRequestReviews] =
    useState(false);
  const [filterCycle, setFilterCycle] = useState(obtainFilterCycle);

  const prefix = process.env.REACT_APP_PREFIX;
  const subscriptionsStorage = localStorage.getItem(`cmSubscriptions${prefix}`);
  const subscriptions =
    (subscriptionsStorage && JSON.parse(subscriptionsStorage)) || {};
  const isReviewsPremium = subscriptions.reviews === "active";
  const statusReviewOptions = [
    {
      value: t("reviewStatus.pending"),
      label: t("reviewStatus.pending"),
      id: "pending",
    },
    {
      value: t("reviewStatus.sent"),
      label: t("reviewStatus.sent"),
      id: "sent",
    },
    {
      value: t("reviewStatus.answered"),
      label: t("reviewStatus.answered"),
      id: "answered",
    },
  ];
  const [count, setCount] = useState(0);
  const [hasCycle, sethasCycle] = useState(false);

  /**
   * Stores the table column data in memory, and is not updated unless useMemo identifies a change in the data.
   */
  const columns = React.useMemo(
    () => [
      {
        Header: t("textTable.nameRelative"),
        accessor: "name",
        Filter: (props) => <InputFilter {...props} />,
        width: isReviewsPremium ? 200 : 500,
      },
      {
        Header: t("textTable.email"),
        accessor: "email",
        Filter: (props) => <InputFilter {...props} />,
        width: isReviewsPremium ? 300 : 600,
      },
      {
        Header: t("textTable.phoneNumer"),
        accessor: "phone",
        Filter: (props) => <InputFilter {...props} />,
        width: isReviewsPremium ? 200 : 450,
      },
      ...(isReviewsPremium
        ? [
            {
              Header: t("textTable.reviewsStatus"),
              accessor: "reviewStatus",
              Filter: (props) => (
                <SelectFilter defaultValues={statusReviewOptions} {...props} />
              ),
              width: 200,
              Cell: (cell) => {
                const status = cell?.value;
                const fullDate = cell?.row?.original?.reviewDate;
                const date =
                  fullDate &&
                  moment.parseZone(fullDate).local().format("DD/MM/YYYY");
                const bgColor = {
                  sent: "warning",
                  pending: "danger",
                  answered: "success",
                };
                const badgeElement = (
                  <Badge bg={bgColor[status]} style={{ width: "80%" }}>
                    {t(`reviewStatus.${status}`)}
                  </Badge>
                );
                return status ? (
                  status != "pending" ? (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip-badge-reviews">
                          {t("shippingDate")}
                          <p>{date}</p>
                        </Tooltip>
                      }
                    >
                      {badgeElement}
                    </OverlayTrigger>
                  ) : (
                    badgeElement
                  )
                ) : (
                  "N/A"
                );
              },
            },
          ]
        : []),
    ],
    [loading]
  );

  //Options to export to excel
  const exportOptions = {
    openAsDownload: true,
    format: "xlsx", //'xlsx' or 'xls' or 'csv'
    filename: t("textDowloadExcel"),
  };

  //Saves the data for the table in memory, and is not updated unless useMemo identifies a change in the data
  const data = React.useMemo(() => dataTable, [loading]);

  const listRelatives = (cycle = filterCycleId) => {
    let schoolCycleId = cycle && cycle.id ? cycle.id : cycle;
    let request = {};

    if (cycle) {
      request.school_cycle_id = schoolCycleId;
      setFilteCycleId(schoolCycleId);
      getCycleSelectedInfo(schoolCycleId);
    }
    setLoading(true);
    GetRelatives(request)
      .then((result) => {
        if (result && result.description) {
          Sentry.captureException(Error(JSON.stringify(result)));
          setError(result.description);
        } else {
          let data = result.data;
          let relatives = [];
          relatives = data.map((item) => {
            return {
              id: item.id,
              index: item.id,
              name: item.first_name + " " + item.last_name,
              phone: item.phone || "N/A",
              email: item.email || "N/A",
              reviewDate: item.review_date,
              reviewStatus: item.review_status,
            };
          });
          setDataTable(relatives);
        }
        setLoading(false);
        setLoadingView(false);
      })
      .finally(() => {
        sethasCycle(true);
      });
  };

  const cancelConfirmModal = () => {
    setShowModalSuccess(false);
  };

  //Delete parent records
  const deleteRelative = () => {
    setShowModalSuccess(false);
    DeleteRelatives(relativesSelectedIds).then((result) => {
      if (result && result.description) {
        Sentry.captureException(Error(JSON.stringify(result)));
        setError(result.description);
      } else {
        listRelatives();
      }
    });
  };

  //Redirect to version 1
  const goApp1 = (path) => {
    var now = new Date();
    now.setDate(now.getDate() + 5);
    goToHref(`/${path}`);
  };

  //Select the entire row and enter the profile redirecting to version 1
  const rowOnclick = (relatives) => {
    goApp1("relative/" + relatives.id);
    localStorage.setItem("filterCycleRelatives", JSON.stringify(filterCycle)); // save the current cycle in the local storage
  };

  /**
   * Function to display the premium review modal or contact sales
   */
  const launchReviewsModal = () => {
    if (isReviewsPremium) {
      setShowModalConfirmRequestReview(true);
      return;
    }
    setShowModalNonPremiumReviews(true);
  };

  /**
   * Function to start the review request process
   */
  const confirmRequestReviews = () => {
    // Call the service to send the email for the reviews to the relatives
    requestReviews();
    // Close confirmation modal
    setShowModalConfirmRequestReview(false);
    // Open success modal
    setShowModalSuccessRequestReviews(true);
  };

  /**
   * Function to send the request to get reviews
   */
  const requestReviews = () => {
    const request = {
      user_ids: relativesSelectedIds,
      school_cycle_id: filterCycleId,
    };
    UserReviewsPost(request).then((result) => {
      if (result && result.description) {
        Sentry.captureException(Error(JSON.stringify(result)));
      }
      listRelatives();
    });
  };

  /**
   * Description: Effect to know if all prospects are selected
   */
  useEffect(() => {
    let selectAll = {
      switch: selectAllRows.switch,
      value: isAllRowsSelected,
    };
    setSelectAllRows(selectAll);
    let userSelectedIds = [];
    userSelectedIds = selectedRowIds.map((item) => item.original.id);
    setRelativesSelectedIds(userSelectedIds);
    if (isReviewsPremium) {
      const hasReviewsAnswered = selectedRowIds.some(
        (relative) => relative.original.reviewStatus === "answered"
      );
      setHasReviewsAnswered(hasReviewsAnswered);
    }
  }, [selectedRowIds]);

  /*
   * Description: Initial loading
   */
  useEffect(() => {
    Sentry.setTag("section", "Relatives");
  }, []);

  /**
   * Description: Function to stop loading when the organization has no cycles
   */
  const stopLoading = () => {
    setLoading(false);
    setLoadingView(false);
    sethasCycle(false);
  };

  /**
   * Obtain complete information of the selected cycle
   * @param {int} cycleId
   */
  const getCycleSelectedInfo = (cycleId) => {
    const cycles = JSON.parse(localStorage.getItem(`cycles${prefix}`));
    const hasCycles = cycles && Array.isArray(cycles) && cycles.length;
    if (hasCycles) {
      const cycleSelected = cycles.find((cycle) => cycle.id == cycleId);
      setFilterCycle(cycleSelected);
    } else {
      setTimeout(() => {
        getCycleSelectedInfo();
      }, 1000);
    }
  };

  //View Table
  return (
    <Container fluid>
      <Row>
        <Col md={12} lg={2}>
          <h2>{t("textTable.relative")}</h2>
        </Col>
        <Col md={12} lg={3} xs={12}>
          {/** Filter Select School Cycle */}
          <SchoolCycle
            useIn="filters"
            handleOnchange={(e) => {
              listRelatives(e?.id || null);
            }}
            getDefaultValues={listRelatives}
            selectedByDefault={true}
            notCycles={stopLoading}
            value={filterCycle}
          />
        </Col>
      </Row>

      {/** Section for print, export, modal buttons */}
      <Row style={{ marginBottom: "20px", marginTop: "20px" }}>
        <Col lg={6}>
          <div>
            <Button
              variant="outline-secondary"
              onClick={() => {
                let selectAll = {
                  switch: !selectAllRows.switch,
                  value: !selectAllRows.value,
                };
                setSelectAllRows(selectAll);
              }}
              style={{ marginRight: "10px" }}
              disabled={!count}
            >
              {!isAllRowsSelected
                ? t("global:buttons.selectAll")
                : t("global:buttons.unselectAll")}
            </Button>
            <Button
              variant="outline-secondary"
              onClick={() => setShowModalSuccess(true)}
              hidden={!relativesSelectedIds.length}
              style={{ marginRight: "10px" }}
            >
              {t("global:titlePermissions.delete")}
            </Button>
            {hasReviewsAnswered ? (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip-answered-reviews">
                    {t("tooltipText.reviewsNoAnswered")}
                  </Tooltip>
                }
              >
                <ButtonGroup>
                  <Button hidden={!relativesSelectedIds.length} disabled={true}>
                    <i className="bi bi-send" />
                    {t("requestReview")}
                  </Button>
                </ButtonGroup>
              </OverlayTrigger>
            ) : (
              <Button
                hidden={!relativesSelectedIds.length}
                onClick={launchReviewsModal}
              >
                <i className="bi bi-send" />
                {t("requestReview")}
              </Button>
            )}
            {/* Total counter of records and selected records in Relative table */}
            {selectedRowIds.length ? (
              selectedRowIds.length == 1 ? (
                <p
                  className="ms-3 d-inline"
                  style={customStyles.textDangerBold}
                >
                  {t("table:textTableViews.selectedRecord", {
                    countSelected: selectedRowIds.length,
                  })}
                </p>
              ) : (
                <p
                  className="ms-3 d-inline"
                  style={customStyles.textDangerBold}
                >
                  {t("table:textTableViews.selectedRecords", {
                    countSelected: selectedRowIds.length,
                  })}
                </p>
              )
            ) : (
              <p className="d-inline" style={customStyles.textGrayBold}>
                {t("table:textTableViews.totalRecords", { count: count })}
              </p>
            )}
          </div>
        </Col>
        <Col lg={6}>
          <div style={{ paddingTop: "5px", float: "right" }}>
            <Dropdown style={{ display: "inline-block", marginRight: "10px" }}>
              <Dropdown.Toggle
                id="downloadOptionRelative"
                variant="outline-secondary"
                disabled={printExport}
              >
                <i className="bi bi-download"></i>
                {t("global:buttons.download")}
              </Dropdown.Toggle>
              <Dropdown.Menu disabled={printExport}>
                <ReactToPrint
                  trigger={() => (
                    <Dropdown.Item>{t("global:buttons.print")}</Dropdown.Item>
                  )}
                  content={() => componentRef.current}
                />
                <ExportToExcel
                  exportData={exportData}
                  exportOptions={exportOptions}
                  printExport={printExport}
                  typeElement="dropdownItem"
                />
              </Dropdown.Menu>
            </Dropdown>
            <a
              href="?createRelative=true"
              onClick={(e) => {
                e.preventDefault();
              }}
              style={{ cursor: !hasCycle && "default" }}
            >
              <Button
                variant="primary"
                onClick={() => setShowModalCreateReleative(true)}
                disabled={!hasCycle}
              >
                <i
                  className="bi bi-person-plus-fill"
                  style={{ fontSize: "15px" }}
                ></i>
                {t("textTable.btnCreateRelative")}
              </Button>
            </a>
          </div>
        </Col>
      </Row>

      {/* TABLA */}
      {loadingView ? (
        <ContentSolid style={{ marginTop: "20px" }}>
          <LoadingTable />
        </ContentSolid>
      ) : (
        <>
          {error}
          <ContentTable startColumFilter={1} lastColumFilter={4}>
            <Table
              data={data}
              rowSelect={true}
              loading={loading}
              columns={columns}
              ref={componentRef}
              rowOnclick={rowOnclick}
              selectAllRows={selectAllRows}
              setExportData={setExportData}
              setPrintExport={setPrintExport}
              setSelectedRowIds={setSelectedRowIds}
              setIsAllRowsSelected={setIsAllRowsSelected}
              linkToIdProfile={`${process.env.REACT_APP_V1}/relative`} //Send the link
              isLinked={true}
              setRowsDisplayed={setCount}
            />
          </ContentTable>
        </>
      )}
      {showModalCreateReleative && (
        <ModalCreateRelative
          updateList={listRelatives}
          showModalCreateReleative={showModalCreateReleative}
          setShowModalCreateReleative={setShowModalCreateReleative}
        />
      )}
      {showModalSuccess ? (
        <ModalSuccess
          showModalSuccess={showModalSuccess}
          title={t("cofirmDelete.title")}
          message={t("cofirmDelete.description")}
          fnAcceptButton={deleteRelative}
          size={"lg"}
          txtBtnAccept={t("global:titlePermissions.delete")}
          fnCancelButton={cancelConfirmModal}
        />
      ) : null}
      {showModalNonPremiumReviews && (
        <ModalNonPremiumReviews
          showModal={showModalNonPremiumReviews}
          closeModal={() => setShowModalNonPremiumReviews(false)}
        />
      )}
      {showModalConfirmRequestReview && (
        <ModalConfirmRequestReview
          showModal={showModalConfirmRequestReview}
          closeModal={() => setShowModalConfirmRequestReview(false)}
          count={selectedRowIds.length}
          confirmRequestReviews={confirmRequestReviews}
        />
      )}
      {showModalSuccessRequestReviews && (
        <ModalSuccess
          fnCancelButton={() => setShowModalSuccessRequestReviews(false)}
          message={t("modalSuccessRequestReviews.description")}
          showModalSuccess={showModalSuccessRequestReviews}
          size={"lg"}
          title={t("modalSuccessRequestReviews.title")}
          txtBtnCancel={t("global:buttons.close")}
        />
      )}
    </Container>
  );
};

export default RelativeView;
