import React, { useState, createRef } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

/* Elements */
import { Modal, Button, Row, Col, FormLabel, FormGroup } from "react-bootstrap";
import SchoolCycle from "../../global/form/SchoolCycle";
import selectStyles from "../../global/GlobalTools";
import TextLoading from "../../global/TextLoading";
import Dropzone from "react-dropzone";
import Select from "react-select";
import Papa from "papaparse";
import fileDownload from "js-file-download";

/* API */
import { DownloadTemplate, GetTeacherListCsv } from "../../../api/class";

const ModalImportSchedule = (props) => {
  const [t] = useTranslation(["global", "superAdmin"]);
  const dropzoneRef = createRef();
  const [showHigherLevel, setShowHigherLevel] = useState(false);
  const [academicPrograms, setAcademicPrograms] = useState([]);
  const [disabledBtn, setDisabledBtn] = useState(true);
  const [schedule, setSchedule] = useState({});
  const [levels, setLevels] = useState([]);
  const [file, setFile] = useState("");
  const [levelSelected, setLevelSelected] = useState(null);
  const [isDownloadingTeachersTemplate, setIsDownloadingTeachersTemplate] =
    useState(false);

  /**
   * Method for selecting the school cycle
   * @param {obj} cycle
   */
  const changeCycle = (cycle) => {
    // Obtain school levels for the school cycle
    const formatLevels = cycle.school_levels.map((level) => {
      return {
        label: level.school_level_name,
        value: level.id,
      };
    });
    // Obtain academic programs for the school cycle
    const programs = cycle.colleges_and_schools
      .map((item) => {
        let values = [];
        for (let schoolLevel of item.school_levels) {
          for (let program of schoolLevel.programs) {
            if (program.enabled == "1") {
              program.label = program.name;
              program.value = program.id;
              values.push(program);
            }
          }
        }
        return values;
      })
      .flat();

    // Add the top level option if there are programs
    if (programs.length) {
      formatLevels.push({
        value: "higherLevel",
        label: t("class:modalImport.higherLevel"),
      });
    }

    setLevels(formatLevels);
    setAcademicPrograms(programs);
    setShowHigherLevel(false);

    schedule.cycle = cycle.id;
    delete schedule.level;
    delete schedule.program;
    setSchedule(schedule);
    setDisabledBtn(true);
    setLevelSelected(null);
  };

  /**
   *
   * @param {obj} level
   * @returns
   */
  const selectLevel = (level) => {
    setLevelSelected(level);
    if (level.value === "higherLevel") {
      delete schedule.level;
      setShowHigherLevel(true);
      return;
    }
    delete schedule.program;
    schedule.level = level.value;
    setSchedule(schedule);
    setShowHigherLevel(false);
    validateSchedule();
  };

  /**
   * Save file csv
   * @param {File} file
   */
  const saveFile = (file) => {
    const catchResult = (result) => {
      let schedulesFormat = [];
      const rows = result.data;
      for (const row of rows) {
        const values = Object.values(row);
        const columns = Object.keys(row);
        if (
          columns?.length == 12 &&
          values[0] != "Fila de ejemplo (borrar)" &&
          values[1] != "Correo electrónico (maestro)" &&
          values[6] != "L-M-Mi-J-V-S-D" &&
          values[2] != "Nombre de la materia"
        ) {
          schedulesFormat.push({
            Clase_Opcional: values[5],
            Dias_de_la_Semana: values[6],
            Espacio_Asignado: values[4],
            Grado: values[3]?.split("")[0],
            Grupo: values[3]?.split("")[1],
            Hora_Fin: values[8],
            Hora_Inicio: values[7],
            Maestro: values[1],
            Materia: values[2],
            Repetir: values[9] ? values[9] : "",
            Titulo_de_Clase: values[0],
            Inicio_Periodo: values[10],
          });
        }
      }
      props.setScheduleDetails(schedulesFormat);
      if (schedulesFormat.length > 0) {
        setFile(file);
      }
    };

    Papa.parse(file[0], {
      header: true,
      download: true,
      skipEmptyLines: true,
      complete: catchResult,
      delimiter: "",
      escapeChar: "",
    });
    schedule.file = file;
    validateSchedule();
  };

  /**
   * Open File
   */
  const openFile = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  /**
   * Download import template
   */
  const downloadTemplate = () => {
    DownloadTemplate();
  };

  /**
   * Show import details for confirmation
   */
  const detailsImport = () => {
    props.setScheduleMain(schedule);
    props.setShowDetailsSchedule(true);
    props.setShowImportSchedule(false);
  };

  /**
   * Validate that the fields are complete
   */
  const validateSchedule = () => {
    if (
      schedule.file &&
      schedule.cycle &&
      (schedule.level || schedule.program)
    ) {
      setDisabledBtn(false);
    } else {
      setDisabledBtn(true);
    }
  };

  /**
   * Description: Function to download the teachers list template
   */
  const downloadTeachersTemplate = () => {
    setIsDownloadingTeachersTemplate(true);
    GetTeacherListCsv()
      .then((response) => {
        if (response.data) {
          fileDownload(response.data, t("class:modalImport.teachersCsv"));
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsDownloadingTeachersTemplate(false);
      });
  };

  return (
    <Modal show={props.showImportSchedule} size="lg">
      <Modal.Header className="modal-header">
        <Modal.Title className="modal-title text-center">
          {t("class:modalImport.importSchedule")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mb-3">
          <Col md={6}> {t("class:modalImport.importScheduleTemplate")}</Col>
          <Col md={6}>
            <Button className="float-end" onClick={downloadTemplate}>
              {t("class:modalImport.downloadTemplate")}
            </Button>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={8}> {t("class:modalImport.checkListTeachers")}</Col>
          <Col xs={4}>
            <Button
              className="float-end"
              onClick={downloadTeachersTemplate}
              disabled={isDownloadingTeachersTemplate}
            >
              {isDownloadingTeachersTemplate ? (
                <TextLoading
                  text={t("global:buttons.downloading")}
                  variant="light"
                />
              ) : (
                t("class:modalImport.downloadTeachersList")
              )}
            </Button>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={6}>
            <FormGroup>
              <FormLabel> {t("class:modalImport.cycle")}</FormLabel>
              <SchoolCycle useIn="filters" handleOnchange={changeCycle} />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <FormLabel>{t("class:modalImport.level")}</FormLabel>
              <Select
                styles={selectStyles}
                options={levels}
                placeholder={t("class:modalImport.selectSchoolLevel")}
                isDisabled={!levels.length}
                onChange={selectLevel}
                value={levelSelected}
              />
            </FormGroup>
          </Col>
        </Row>
        {showHigherLevel && (
          <Row>
            <Col md={6}>
              <FormGroup>
                <FormLabel>{t("class:modalImport.academicOffering")}</FormLabel>
                <Select
                  styles={selectStyles}
                  options={academicPrograms}
                  placeholder={t("class:modalImport.selectOffer")}
                  isDisabled={!academicPrograms.length}
                  onChange={(program) => {
                    schedule.program = program.id;
                    setSchedule(schedule);
                    validateSchedule();
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
        )}
        <Row className="mt-4">
          <Col md={12}>
            <Dropzone
              ref={dropzoneRef}
              accept=".csv, .xlsx, .xls"
              noClick
              noKeyboard
              onDrop={saveFile}
            >
              {({ getRootProps, getInputProps }) => (
                <div
                  {...getRootProps()}
                  style={{
                    position: "relative",
                    height: "auto",
                    borderWidth: "2px",
                    borderColor: "rgb(194, 194, 194)",
                    borderStyle: "dashed",
                    borderRadius: "5px",
                  }}
                >
                  <center>
                    <p
                      style={{
                        fontWeight: "normal",
                        fontFamily: "'Open Sans', sans-serif",
                        fontSize: "14px",
                        color: "#ea2c54",
                        marginTop: "20px",
                        marginBottom: "20px",
                      }}
                    >
                      {file && file[0].name
                        ? file[0].name
                        : t("importCSV.dropFile")}
                      <br />
                      {file ? "" : t("importCSV.dropFileOr")}
                      <br />
                      <br />
                      <input {...getInputProps()} />
                      <Button
                        type="button"
                        className="btn btn-text btn-white"
                        onClick={openFile}
                      >
                        {t("importCSV.uploadFile")}
                      </Button>
                    </p>
                  </center>
                </div>
              )}
            </Dropzone>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-secondary"
          onClick={() => props.setShowImportSchedule(false)}
        >
          {t("global:buttons.cancel")}
        </Button>
        <Button type="submit" onClick={detailsImport} disabled={disabledBtn}>
          {t("global:buttons.import")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ModalImportSchedule.propTypes = {
  showImportSchedule: PropTypes.bool,
  setShowImportSchedule: PropTypes.func,
  setShowDetailsSchedule: PropTypes.func,
  setScheduleDetails: PropTypes.func,
  setScheduleMain: PropTypes.func,
};

export default ModalImportSchedule;
