// Libraries
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import firebase from "../firebase/firebase";
import * as Sentry from "@sentry/react";

// Custom Components
import {
  GetNotificationToken,
  SetNotificationToken,
  GetUserNotification,
  SetUserNotification,
} from "./global/GlobalTools";

// API Services
import { SaveDeviceToken, deleteDeviceToken } from "../api/Session";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const PushNotifications = (props) => {
  const { apiKey = "", getNotifications = () => {} } = props;
  const prefix = process.env.REACT_APP_PREFIX;
  const isSupported = () =>
    "Notification" in window &&
    "serviceWorker" in navigator &&
    "PushManager" in window;

  // Initial Values
  useEffect(() => {
    Sentry.setTag("section", "Push Notifications");
    if (!isSupported()) return;
    // Request permission for notifications if access is set by default
    if (window.Notification && window.Notification.permission === "default") {
      window.Notification.requestPermission();
    }
    // Load Service Worked
    if (firebase.messaging.isSupported() && apiKey) {
      loadPushNotifications(apiKey);
    }
  }, [apiKey]);

  // Functions

  /**
   * Puporse: Load Service Worked
   * @param {String} apiKey
   */
  const loadPushNotifications = (apiKey) => {
    // Check if a new firebase token is required
    const notificationToken = GetNotificationToken();
    // Get User Token Notifiaction
    const userNotification = GetUserNotification();
    const userId = localStorage.getItem(`cmUserID${prefix}`);
    // Validate Notification Permission
    if (window.Notification?.permission !== "granted") {
      SetNotificationToken("");
      SetUserNotification("");
      return;
    }
    // Load the notification listener in the foreground
    getPushNotifications();
    // Validate organization notification
    if (!notificationToken) {
      getFirebaseToken(apiKey);
    } else if (
      userNotification &&
      userNotification !== "false" &&
      userNotification !== userId
    ) {
      removeFirebaseToken(notificationToken, apiKey);
    }
  };

  /**
   * Description: Function to get push notifications from firebase
   */
  const getPushNotifications = () => {
    try {
      const messaging = getMessaging();
      onMessage(messaging, ({ data }) => {
        getNotifications();
        // Custom options
        const message = data.type_user
          ? `${data.type_user} | ${data.body}`
          : data.body;
        const notificationOption = {
          body: message,
        };
        //add onClick event to notification
        if (window.Notification?.permission === "granted") {
          const notification = new window.Notification(
            data.title,
            notificationOption
          );
          notification.onclick = function (ev) {
            ev.preventDefault();
            window.location.replace(data.link);
            notification.close();
          };
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Description: Function to get firebase token
   * @param {string} apiKey
   */
  const getFirebaseToken = (apiKey) => {
    try {
      const permission = Notification.permission;
      if (permission === "granted") {
        const messaging = getMessaging();
        const userId = localStorage.getItem(`cmUserID${prefix}`);
        const vapidKey =
          "BPShKEqYpGKCcIrcIZDTn3v7zq77tl1JWKI1VB_ufFKIE6tYbrK52E0iwubgCycnaRsp88LjW0MYao0BCi9ewIs";
        getToken(messaging, { vapidKey })
          .then((token) => {
            saveNotificationToken(token, apiKey, userId);
          })
          .catch(() => {
            getFirebaseToken(apiKey);
          });
      }
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Description: Function to remove firebase token
   * @param {string} notificationToken
   * @param {string} apiKey
   */
  const removeFirebaseToken = (notificationToken, apiKey) => {
    firebase
      .messaging()
      .deleteToken()
      .then(() => {
        // Delete device token
        removeNotificationToken(notificationToken);
      })
      .then(() => {
        getFirebaseToken(apiKey);
      })
      .catch((error) => {
        Sentry.captureException(Error(JSON.stringify(error)));
      });
  };

  /**
   * Description: Function to save a notification token
   * @param {string} token
   * @param {string} apiKey
   * @param {string} userId
   */
  const saveNotificationToken = (token, apiKey, userId) => {
    const device = {
      device_token: token,
      api_key: apiKey,
      user_id: userId,
      type: "web",
    };
    SaveDeviceToken(device)
      .then(() => {
        SetNotificationToken(token);
        SetUserNotification(userId);
      })
      .catch((error) => {
        Sentry.captureException(Error(JSON.stringify(error)));
      });
  };

  /**
   * Description: Function to remove a notification token
   * @param {string} notificationToken
   */
  const removeNotificationToken = (notificationToken) => {
    deleteDeviceToken(notificationToken)
      .then(() => {
        SetNotificationToken("");
        SetUserNotification("");
      })
      .catch((error) => {
        Sentry.captureException(Error(JSON.stringify(error)));
      });
  };

  // Return notifiaction view
  return <div id="pushNotificationsComponent" />;
};

export default PushNotifications;

PushNotifications.propTypes = {
  apiKey: PropTypes.string,
  getNotifications: PropTypes.func,
};
