// Libraries
import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Button, Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ReactToPrint from "react-to-print";
import ExcellentExport from "excellentexport";
import * as moment from "moment";

// Custom Components
import ContentSolid from "../global/ContentSolid";
import { LoadingTable } from "../lazyLoading/LazyLoading";
import ContentTable from "../table/ContentTable";
import { Table } from "../table/Table";
import ModalConfirmDeletedSubject from "./modals/ModalConfirmDeletedSubject";
import ModalCreateSubject from "./modals/ModalCreateSubject";

// API Services
import { GetSubjects } from "../../api/class";
import { getUrlParamValue, nameCookie } from "../global/GlobalTools";

/**Get param to open create Subject Modal */
const createSubject = getUrlParamValue("createSubject");
//Custom styles section
const stylesCustom = {
  textDangerBold: {
    color: "#ea2c54",
    fontWeight: "bold",
    fontSize: "small",
  },
  textGrayBold: {
    color: "#c8cbcc",
    fontWeight: "bold",
    fontSize: "small",
  },
};

const Subjects = () => {
  const [t] = useTranslation([
    "global",
    "superAdmin",
    "reports",
    "class",
    "cycles",
  ]);
  const componentRef = useRef(); //Create the reference for printing
  const [loadingView, setLoadingView] = useState(true);
  const [subjects, setSubjects] = useState([]);
  const prefix = process.env.REACT_APP_PREFIX;
  const [schoolCycles, setSchoolCycles] = useState([]);
  const [schoolCycle, setSchoolCycle] = useState(null);
  const [readyLanguages, setReadyLanguages] = useState(false);
  const [selectAllRows, setSelectAllRows] = useState({
    switch: false,
    value: false,
  });
  const [isAllRowsSelected, setIsAllRowsSelected] = useState(false);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [schoolLevels, setSchoolLevels] = useState([]);
  const [schoolLevel, setSchoolLevel] = useState(null);
  const [academicOffert, setAcademiOffert] = useState(null);
  const [exportData, setExportData] = useState([]);
  const [printExport, setPrintExport] = useState(true);
  const [count, setCount] = useState(0);
  const [schoolPrograms, setschoolPrograms] = useState([]);
  const [showModalConfirmDeleted, setShowModalConfirmDeleted] = useState(false);
  const [showCreateSubject, setShowCreateSubject] = useState(createSubject);
  const [subjectToEdit, setSubjectToEdit] = useState(null);
  const [hasCycle, sethasCycle] = useState(false);

  //Record reporting options
  const actionOptions = [
    { value: "list", label: "superAdmin:view.downloadPDF" },
    { value: "excel", label: "superAdmin:view.downloadExcel" },
  ];
  const columns = React.useMemo(
    () => [
      {
        Header: t("reports:headerTable.name"),
        accessor: "name",

        width: 540,
      },
      {
        Header: t("reports:headerTable.description"),
        accessor: "description",
        width: 540,
      },
      {
        Header: t("reports:headerTable.totalClasses"),
        accessor: "total",
        width: 540,
      },
    ],
    [loadingView]
  );

  // incialLoad
  const incialLoad = () => {
    let cyclesLocal = JSON.parse(localStorage.getItem(`cycles${prefix}`)) || [];
    // Get the status of the service that obtains the school cycles
    const nameStatusCycles = nameCookie("StatusCycles");
    const statusCycles = localStorage.getItem(nameStatusCycles);
    const hasCycles =
      cyclesLocal && Array.isArray(cyclesLocal) && cyclesLocal.length;
    if (hasCycles) {
      setSchoolCycles(cyclesLocal);
      let currentCycle = false;
      const today = moment().unix();
      for (let cycle of cyclesLocal) {
        if (
          today > cycle.start_time &&
          today < cycle.end_time &&
          cycle.current_cycle == 1
        ) {
          currentCycle = cycle;
        }
      }
      currentCycle = currentCycle || cyclesLocal[0];
      // Get current school cycle
      if (currentCycle) {
        //Get School Levels}
        getLevels(currentCycle);
        setSchoolCycle(currentCycle);
      }
      sethasCycle(true);
    } else if (!statusCycles) {
      setTimeout(() => {
        incialLoad();
      }, 1000);
    } else {
      setLoadingView(false);
      sethasCycle(false);
    }
  };

  /**
   * Initial Loading
   */
  useEffect(() => {
    incialLoad();
  }, []);

  /**
   * Load initial translations
   */
  useEffect(() => {
    if (typeof t == "function") {
      setTimeout(() => {
        setReadyLanguages(true);
      }, 500);
    }
  }, [t]);

  /**
   * Puporse: Get initial Subjects values
   * @param {Number} schoolCycleId
   */
  const getSubjects = (schoolCycleId, schoolLevel = null, level = "Basic") => {
    setLoadingView(true);
    const values = {};
    if (schoolCycleId) values.school_cycle_id = schoolCycleId;
    if (schoolLevel) {
      switch (level) {
        case "Basic":
          values.organization_school_level_id = schoolLevel.value;
          break;
        case "higherLevel":
          values.program_id = schoolLevel.value;
          break;
        default:
          break;
      }
    }
    GetSubjects(values)
      .then((result) => {
        if (result && result.data) {
          const subjects = result.data.map((subject) => {
            return {
              index: subject.id,
              name: subject.name,
              description: subject.description,
              total: subject.number_of_classes,
              color: subject.color || "#fedae3",
            };
          });
          setCount(subjects.length);
          setSubjects(subjects);
        }
      })
      .catch()
      .finally(() => setLoadingView(false));
  };

  // Saves the data for the table in memory, and is not updated unless useMemo identifies a change in the data
  const data = React.useMemo(() => subjects, [loadingView]);

  /**
   * Description: Effect to know if all subjects are selected
   */
  useEffect(() => {
    let selectAll = {
      switch: selectAllRows.switch,
      value: isAllRowsSelected,
    };
    setSelectAllRows(selectAll);
  }, [selectedRowIds]);

  /**
   * Obtains the school levels (School levels) of the selected School cycle.
   * @param {Id} schoolCycleId
   */
  const getLevels = (schoolCycle) => {
    // Filtering school cycles levels
    let schoolLevels = [];
    //Check if the school cycle has higher education
    const programs = schoolCycle.colleges_and_schools
      .map((item) => {
        let values = [];
        for (let schoolLevel of item.school_levels) {
          for (let program of schoolLevel.programs) {
            if (program.enabled == "1") {
              program.label = program.name;
              program.value = program.id;
              values.push(program);
            }
          }
        }
        return values;
      })
      .flat();
    // Get school cycle levels from school cycle
    const schoolCycleLevels = schoolCycle.school_levels;
    if (schoolCycleLevels && schoolCycleLevels.length != 0) {
      for (let level of schoolCycleLevels) {
        // Get grades grups
        let gradeGroups = [];
        if (level.grades && level.grades.length !== 0) {
          const grades = level.grades;
          for (let grade of grades) {
            if (grade.groups && grade.groups.length !== 0) {
              const groups = grade.groups;
              groups.forEach((group) => {
                const item = {
                  id: group.grade_group_grade_level_id,
                  value: group.grade_group_grade_level_id,
                  label: `${grade.grade_level} ${group.name}`,
                };
                gradeGroups.push(item);
              });
            }
          }
        }
        // Get school level item
        schoolLevels.push({
          id: level.id,
          value: level.organization_school_level_id,
          label: level.school_level_name,
          grades: gradeGroups,
        });
      }
    }
    // Add higher education option
    if (programs.length) {
      setschoolPrograms(programs);
      schoolLevels.push({
        id: "higherLevel",
        value: "higherLevel",
        label: t("class:modalImport.higherLevel"),
      });
    }
    if (schoolLevels && schoolCycleLevels.length !== 0) {
      setSchoolLevel(schoolLevels[0]);
      getSubjects(schoolCycle.id, schoolLevels[0]);
    } else if (programs.length) {
      setSchoolLevel(schoolLevels[0]);
      setAcademiOffert(programs[0]);
      getSubjects(schoolCycle.id, programs[0], "higherLevel");
    } else {
      getSubjects(schoolCycle.id);
    }

    setSchoolLevels(schoolLevels);
  };

  //Export to Excel
  const downloadFile = (exportOptions, exportData) => {
    ExcellentExport.convert(exportOptions, exportData);
  };

  /**
   * Updates the table after some action on the records
   */
  const refreshList = () => {
    let schooling =
      schoolLevel.id == "higherLevel" ? academicOffert : schoolLevel;
    let schoolingLevel =
      schoolLevel.id == "higherLevel" ? "higherLevel" : "Basic";
    getSubjects(schoolCycle.id, schooling, schoolingLevel);
  };

  /**
   * Modal launcher to edit a subject
   * @param {obj} subject
   */
  const editSubject = (subject) => {
    setSubjectToEdit(subject);
    setShowCreateSubject(true);
  };

  //Options to export to excel
  const exportOptions = {
    openAsDownload: true,
    format: "xlsx", //'xlsx' or 'xls' or 'csv'
    filename: t("class:subjects.excelName"),
  };

  return (
    <div>
      <Row className="mb-3">
        <Col md={9}>
          {/**Select All Button */}
          <Button
            variant="outline-secondary"
            onClick={() => {
              let selectAll = {
                switch: !selectAllRows.switch,
                value: !selectAllRows.value,
              };
              setSelectAllRows(selectAll);
            }}
            disabled={!count}
          >
            {readyLanguages
              ? t(
                  !isAllRowsSelected
                    ? "global:buttons.selectAll"
                    : "global:buttons.unselectAll"
                )
              : null}
          </Button>

          {/* Delete selected items button */}
          {selectedRowIds.length > 0 && (
            <Button
              variant="outline-secondary"
              className="ms-3"
              onClick={() => setShowModalConfirmDeleted(true)}
            >
              {t("class:main.delete")}
            </Button>
          )}
          {/** Filter Select School Cycle */}
          <Dropdown className="ms-3" style={{ display: "inline-block" }}>
            <Dropdown.Toggle
              id="downloadOptionCycle"
              variant="outline-secondary"
              disabled={!hasCycle}
            >
              {schoolCycle?.label || t("cycles:select.placeholder")}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {schoolCycles.map((cycle) => {
                return (
                  <Dropdown.Item
                    key={cycle.value}
                    onClick={() => {
                      setSchoolLevel(null);
                      setAcademiOffert(null);
                      // Get School levels
                      getLevels(cycle);
                      setSchoolCycle(cycle);
                    }}
                  >
                    {cycle.label}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
          {/**schoolLevels */}
          <Dropdown className="ms-3" style={{ display: "inline-block" }}>
            <Dropdown.Toggle
              id="downloadOptionSchoolLevels"
              variant="outline-secondary"
              disabled={!hasCycle}
            >
              {schoolLevel?.label || t("class:modalImport.selectSchoolLevel")}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {schoolLevels.map((schoolLevel) => {
                return (
                  <Dropdown.Item
                    key={schoolLevel.value}
                    onClick={() => {
                      setSchoolLevel(schoolLevel);
                      if (schoolLevel.id !== "higherLevel") {
                        getSubjects(schoolCycle.id, schoolLevel, "Basic");
                      } else {
                        setAcademiOffert(schoolPrograms[0]);
                        getSubjects(
                          schoolCycle.id,
                          schoolPrograms[0],
                          "higherLevel"
                        );
                      }
                    }}
                  >
                    {schoolLevel.label}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
          {/** higherLevel*/}
          {schoolLevel && schoolLevel.id == "higherLevel" && (
            <Dropdown className="ms-3" style={{ display: "inline-block" }}>
              <Dropdown.Toggle
                id="downloadOptionSchoolLevels"
                variant="outline-secondary"
              >
                {academicOffert?.label ||
                  t("class:modalImport.academicOffering")}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {schoolPrograms.map((offer) => {
                  return (
                    <Dropdown.Item
                      key={offer.value}
                      onClick={() => {
                        setAcademiOffert(offer);
                        getSubjects(schoolCycle.id, offer, "higherLevel");
                      }}
                    >
                      {offer.label}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          )}
          {/* Total counter of records and selected records in Subjects table */}
          {selectedRowIds.length ? (
            selectedRowIds.length == 1 ? (
              <p className="ms-3 d-inline" style={stylesCustom.textDangerBold}>
                {t("table:textTableViews.selectedRecord", {
                  countSelected: selectedRowIds.length,
                })}
              </p>
            ) : (
              <p className="ms-3 d-inline" style={stylesCustom.textDangerBold}>
                {t("table:textTableViews.selectedRecords", {
                  countSelected: selectedRowIds.length,
                })}
              </p>
            )
          ) : (
            <p className="ms-3 d-inline" style={stylesCustom.textGrayBold}>
              {t("table:textTableViews.totalRecords", {
                count: count,
              })}
            </p>
          )}
        </Col>

        <Col md={3}>
          <a
            href="?createSubject=true"
            onClick={(e) => {
              e.preventDefault();
            }}
            style={{ textDecoration: "none" }}
          >
            <Button
              className="float-end ms-3"
              onClick={() => setShowCreateSubject(true)}
              disabled={!(schoolLevel || academicOffert)}
            >
              {t("class:modalCreateSubject.title")}
            </Button>
          </a>
          <Dropdown className="float-end" style={{ display: "inline-block" }}>
            <Dropdown.Toggle
              id="dropdownReportCards"
              variant="outline-secondary"
              disabled={!count}
            >
              <i className="bi bi-download"></i>
              {t("global:buttons.download")}
            </Dropdown.Toggle>
            <Dropdown.Menu disabled={printExport}>
              {actionOptions.map((action) => {
                return action.value == "list" ? (
                  <ReactToPrint
                    trigger={() => (
                      <Dropdown.Item>{t(action.label)}</Dropdown.Item>
                    )}
                    content={() => componentRef.current}
                  />
                ) : (
                  <ReactToPrint
                    trigger={() => (
                      <Dropdown.Item>{t(action.label)}</Dropdown.Item>
                    )}
                    content={() => downloadFile(exportOptions, exportData)}
                  />
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>

      {loadingView ? (
        <ContentSolid>
          <LoadingTable />
        </ContentSolid>
      ) : (
        <ContentTable className="mt-3" startColumFilter={1} lastColumFilter={4}>
          <Table
            ref={componentRef}
            columns={columns}
            data={data}
            setExportData={setExportData}
            setPrintExport={setPrintExport}
            rowSelect={true}
            setSelectedRowIds={setSelectedRowIds}
            setIsAllRowsSelected={setIsAllRowsSelected}
            selectAllRows={selectAllRows}
            rowOnclick={editSubject}
            setRowsDisplayed={setCount}
          ></Table>
        </ContentTable>
      )}
      {showModalConfirmDeleted && (
        <ModalConfirmDeletedSubject
          showModalConfirmDeleted={showModalConfirmDeleted}
          setShowModalConfirmDeleted={setShowModalConfirmDeleted}
          getSubjects={refreshList}
          items={selectedRowIds}
        />
      )}

      {showCreateSubject && (
        <ModalCreateSubject
          showCreateSubject={showCreateSubject}
          setShowCreateSubject={() => {
            setShowCreateSubject(false);
            setSubjectToEdit(null);
          }}
          schoolCycle={schoolCycle}
          schoolLevel={schoolLevel}
          academicOffert={academicOffert}
          refreshList={refreshList}
          subjectToEdit={subjectToEdit}
        />
      )}
    </div>
  );
};
export default Subjects;
