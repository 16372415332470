import axios from "axios";
import { validateErrorResponse } from "./AxiosExtension";

const prefix = process.env.REACT_APP_PREFIX;

/**
 * Purpose: Get the debit records
 * @param {Object} data
 * @returns
 */
export const GetClassLevel = async (data) => {
  const axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/classes`;
  axiosOptions.params = data;

  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    validateErrorResponse(error.response);
    return error;
  }
};

/**
 * Purpose: Get Templates report cards
 * @param {Object} data
 * @returns
 */
export const GetTemplates = async (data = {}) => {
  const axiosOptions = {};
  data.organization_id = localStorage.getItem(`cmOrganizationID${prefix}`);
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/report_cards_templates`;
  axiosOptions.params = data;

  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    validateErrorResponse(error.response);
    return error;
  }
};

/**
 * Purpose: Service to create a new template
 * @param {obj} data // object containing the parameters of the new template
 * @returns {JSON object} JSON object
 */
export const CreateTemplate = async (data) => {
  const axiosOptions = {};
  axiosOptions.method = "post";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/report_cards_templates`;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

/**
 * Purpose: Service to update a template
 * @param {obj} data // object containing the template data to be updated
 * @returns {JSON object} JSON object
 */
export const UpdateTemplate = async (data) => {
  const axiosOptions = {};
  axiosOptions.method = "post";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/report_cards_templates/update`;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

/**
 * Purpose: Service to delete a template
 * @param {int} templateId //id of the template to be deleted
 * @returns {JSON object} JSON object
 */
export const DeleteTemplate = async (templateId) => {
  const axiosOptions = {};
  axiosOptions.method = "delete";
  axiosOptions.data = { id: templateId };
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/report_cards_templates`;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * Obtains the configuration of the active cycle of the organization.
 * @returns {JSON object} JSON object
 */
export const GetSchoolCycles = async () => {
  const axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/school_cycles`;
  axiosOptions.params = {
    visible: "admin",
  };
  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    validateErrorResponse(error.response);
    return error;
  }
};

/**
 * Purpose: Obtains ballot data by organization and by token
 * @returns
 */
export const GetReportCardList = async (data) => {
  const axiosOptions = {};
  data.organization_id = localStorage.getItem(`cmOrganizationID${prefix}`);
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.params = data;
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/report_cards_templates/list`;

  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    validateErrorResponse(error.response);
    return error.response.data.error;
  }
};

/**
 * Description: Service to release the grades
 * @param {object}  [data={}]
 * @returns {JSON object} JSON object
 */
export const ReleaseGrades = async (data = {}) => {
  const axiosOptions = {};
  axiosOptions.method = "post";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/report_cards/release_and_send_notifications`;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

/**
 * Description: Service to get the report card pdf
 * @param {object}  [data={}]
 * @returns {JSON object} JSON object
 */
export const GetReportCardPdf = async (data = {}) => {
  const axiosOptions = {};
  axiosOptions.method = "post";
  axiosOptions.data = {
    student_id: data.student_id,
    organization_school_level_cycle_id: data.organization_school_level_cycle_id,
  };
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/report_cards`;
  axiosOptions.responseType = "blob";
  try {
    const response = await axios(axiosOptions);
    var url = window.URL.createObjectURL(response.data);
    if (data.justDownload) {
      // Download the PDF
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", data.studentName + ".pdf");
      document.body.appendChild(link);
      link.click();
    } else {
      // Print the PDF
      window.open(url);
    }
    return response;
  } catch (error) {
    return error.response.data;
  }
};

/**
 * Description: Service to get the report card info
 * @param {object}  [data={}]
 * @returns {JSON object} JSON object
 */
export const GetReportCard = async (data) => {
  const axiosOptions = {};
  data.organization_id = localStorage.getItem(`cmOrganizationID${prefix}`);
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.params = data;
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/report_cards/view`;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return error.response.data.error;
  }
};

/**
 * Description: Service to get the report card breackdown
 * @param {object}  [data={}]
 * @returns {JSON object} JSON object
 */
export const GetReportCardDetailedView = async (data) => {
  const axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.params = data;
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/report_cards/detailed_view`;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return error.response.data.error;
  }
};
