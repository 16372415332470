/**
 * Sessions
 *
 * Purpose: Contains session-related functions
 *
 * @author Marisol Torres <marisol@inclancommunications.com>
 * @date 08/03/2022 12:06:25 PM
 */

import axios from "axios";
import { validateErrorResponse } from "./AxiosExtension";
import Cookies from "js-cookie";

const prefix = process.env.REACT_APP_PREFIX;
const platform = process.env.REACT_APP_PLATFORM_SHORT;
let axiosOptions = {
  method: "get",
};

/**
 * Porpose: Close session
 * @returns
 */
export const Logout = async () => {
  let data = {
    token: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.method = "post";
  axiosOptions.data = data;
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v1/users/logout`;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
  }
};

/**
 * Purpose: Delete all cookies and localStorage
 */
export const TokenDestroy = () => {
  let pwtoken = nameCookie("Token");
  let userID = nameCookie("UserID");
  let group = nameCookie("Group");
  let organizationID = nameCookie("OrganizationID");
  let currentClass = nameCookie("CurrentClass");
  let cycleID = nameCookie("CycleId");
  let hideMenus = nameCookie("HideMenus");
  const tokenNotification = nameCookie("NotificationToken");
  const userNotifiaction = nameCookie("UserNotification");
  const organizationNotification = nameCookie("OrganizationNotification");
  let domain = window.location.href.includes(".campusmovil.com")
    ? ".campusmovil.com"
    : window.location.hostname;
  Cookies.remove("name", { path: "", domain: ".yourdomain.com" });
  Cookies.remove(pwtoken, { domain: domain, path: "/" });
  Cookies.remove(userID, { domain: domain, path: "/" });
  Cookies.remove(organizationID, { domain: domain, path: "/" });
  Cookies.remove(group, { domain: domain, path: "/" });
  Cookies.remove(currentClass, { domain: domain, path: "/" });
  Cookies.remove(cycleID, { domain: domain, path: "/" });
  Cookies.remove(hideMenus, { domain: domain, path: "/" });
  Cookies.remove(userNotifiaction, { domain: domain, path: "/" });
  Cookies.remove(organizationNotification, { domain: domain, path: "/" });
  Cookies.remove(tokenNotification, { domain: domain, path: "/" });
  localStorage.clear();
  //remove all cookies
  clearAllCookies();
};

/**
 * Function to clear all cookies
 */
function clearAllCookies() {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
  }
}

/**
 * Purpose: Define the name of the cookie according to the platform and the environment
 * @param {*} name
 * @returns
 */
export function nameCookie(name) {
  let nameToken = `${platform}${name}${prefix}`;
  return nameToken;
}

/**
 * SaveDeviceToken
 * Purpose: records the user-related device token
 *
 * @param {obj} data | contains: api_key, user_id, type='web', device_token;
 */
export const SaveDeviceToken = async (data) => {
  axiosOptions = {};
  axiosOptions.method = "post";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v1/devices/register`;

  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    return error;
  }
};

/**
 * Porpose: Get the list of permissions for the user
 * @returns
 */
export const GetPermissions = async (token) => {
  axiosOptions.headers = {
    Authorization: token,
  };
  axiosOptions.method = "get";
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/permissions/user`;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
  }
};

/**
 * Delete DeviceToken
 * Purpose: records the user-related device token
 *
 * @param {String} notificarionToken Organization Notification Token
 */
export const deleteDeviceToken = async (notificarionToken) => {
  axiosOptions = {};
  axiosOptions.method = "post";
  axiosOptions.data = {
    device_token: notificarionToken,
  };
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v1/devices/delete`;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return error.response;
  }
};
