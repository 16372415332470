import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Alert, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const customStyles = {
  rowTable: {
    width: "max-content",
    padding: "0px 10px",
  },
};

const ModalDetailsSchedule = (props) => {
  const [t] = useTranslation(["global", "class"]);
  const [smallScreen, setSmallScreen] = useState(screen.width < 1920);

  const headers = [
    {
      title: t("class:importDetails.classTitle"),
      headWidth: "10%",
      smallHeadWidth: "160px",
      accesor: "Titulo_de_Clase",
    },
    {
      title: t("class:importDetails.staff"),
      headWidth: "16%",
      smallHeadWidth: "160px",
      accesor: "Maestro",
    },
    {
      title: t("class:importDetails.subject"),
      headWidth: "11%",
      smallHeadWidth: "140px",
      accesor: "Materia",
    },
    {
      title: t("class:importDetails.grade"),
      headWidth: "4%",
      smallHeadWidth: "65px",
      accesor: "Grado",
    },
    {
      title: t("class:importDetails.group"),
      headWidth: "4%",
      smallHeadWidth: "65px",
      accesor: "Grupo",
    },
    {
      title: t("class:importDetails.assignedSpace"),
      headWidth: "8%",
      smallHeadWidth: "150px",
      accesor: "Espacio_Asignado",
    },
    {
      title: t("class:importDetails.optionalClass"),
      headWidth: "8%",
      smallHeadWidth: "130px",
      accesor: "Clase_Opcional",
    },
    {
      title: t("class:importDetails.weekdays"),
      headWidth: "10%",
      smallHeadWidth: "150px",
      accesor: "Dias_de_la_Semana",
    },
    {
      title: t("class:importDetails.startTime"),
      headWidth: "7%",
      smallHeadWidth: "100px",
      accesor: "Hora_Inicio",
    },
    {
      title: t("class:importDetails.endTime"),
      headWidth: "7%",
      smallHeadWidth: "100px",
      accesor: "Hora_Fin",
    },
    {
      title: t("class:importDetails.repeat"),
      headWidth: "7%",
      smallHeadWidth: "75px",
      accesor: "Repetir",
    },
    {
      title: t("class:importDetails.periodStart"),
      headWidth: "8%",
      smallHeadWidth: "150px",
      accesor: "Inicio_Periodo",
    },
  ];

  // Initial charge creating a listener
  useEffect(() => {
    // Resolution limit listeners
    window.addEventListener("resize", function () {
      setSmallScreen(screen.width < 1920);
    });
  }, []);

  return (
    <Modal
      show={props.showDetailsSchedule}
      size="xl"
      dialogClassName="modal-xxl"
    >
      <Modal.Header className="modal-header">
        <Modal.Title className="modal-title text-center">
          {t("class:modalImport.importSchedule")}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={smallScreen ? { overflowX: "auto" } : {}}>
        <Row style={smallScreen ? customStyles.rowTable : {}}>
          {headers.map((head, index) => {
            return (
              <div
                key={index}
                style={{
                  width: smallScreen ? head.smallHeadWidth : head.headWidth,
                }}
                className={smallScreen ? "px-0" : ""}
              >
                <p className="fw-bold">{head.title}.</p>
              </div>
            );
          })}
        </Row>

        {props.scheduleDetails.map((item, i) => {
          return (
            <React.Fragment key={i}>
              <Row style={smallScreen ? customStyles.rowTable : {}}>
                {headers.map((head, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        overflowWrap: "break-word",
                        width: smallScreen
                          ? head.smallHeadWidth
                          : head.headWidth,
                      }}
                      className={smallScreen ? "px-0" : ""}
                    >
                      <p>{item[head.accesor]}</p>
                    </div>
                  );
                })}
              </Row>
              <div
                style={{
                  borderTop: "solid 2px #eeeceb",
                  width: smallScreen ? "1422px" : "99%",
                  marginBottom: "10px",
                }}
              ></div>
            </React.Fragment>
          );
        })}

        {/** Show error message */}
        {props.error && (
          <Row>
            <Col md={12} className="mt-3">
              <Alert variant="warning">{props.error}</Alert>
            </Col>
          </Row>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="outline-secondary"
          onClick={() => {
            props.setShowDetailsSchedule(false);
            props.setShowImportSchedule(true);
            props.cleanErrors();
          }}
        >
          {t("global:buttons.cancel")}
        </Button>
        <Button type="submit" onClick={props.sendImportSchedules}>
          {t("class:importDetails.importCSV")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ModalDetailsSchedule.propTypes = {
  showDetailsSchedule: PropTypes.bool,
  setShowDetailsSchedule: PropTypes.func,
  setShowImportSchedule: PropTypes.func,
  sendImportSchedules: PropTypes.func,
  scheduleDetails: PropTypes.array,
  error: PropTypes.string,
  cleanErrors: PropTypes.func,
};
export default ModalDetailsSchedule;
