import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Dropdown,
  Popover,
  OverlayTrigger,
  Modal,
  Button,
  Spinner,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { GetReportCardDetailedView } from "../../api/ReportCards";
import PropTypes from "prop-types";

const customStyles = {
  reportCardBase: {
    backgroundColor: "#ffffff",
    padding: "40px",
    marginBottom: "20px",
  },
  reportCardBaseMovil: {
    backgroundColor: "#ffffff",
    padding: "10px",
    marginBottom: "20px",
  },
  columnClasses: {
    width: "25%",
  },
  columnPeriods: {
    width: "75%",
    overflowX: "auto",
    overflowY: "hidden",
  },
  columnScore: {
    // width: "25%",
    // minWidth: "80px",
    border: "solid 1px #e9ecef",
    borderRight: "none",
    borderLeft: "none",
  },
  columnScoreRed: {
    backgroundColor: "#FFF0F3",
    border: "solid 1px #e9ecef",
    borderRight: "none",
    borderLeft: "none",
  },
  verticalText: {
    writingMode: "vertical-rl",
    transform: "rotate(180deg)",
    textAlign: "center",
    // whiteSpace: "nowrap",
    borderRight: "solid 1px #e9ecef",
    borderTop: "solid 1px #e9ecef",
    borderBottom: "solid 1px #e9ecef",
    padding: "8px",
  },
  fillCaracter: {
    background:
      "linear-gradient(to right, black 50%, rgba(255, 255, 255, 0) 0%)",
    backgroundSize: "10px 1px" /* Ajusta el tamaño del carácter repetido */,
    backgroundRepeat: "repeat-x",
    color: "transparent" /* Hace que el texto sea invisible */,
    lineHeight: 3 /* Ajusta la altura de línea si es necesario */,
  },
  shadowRowHover: {
    boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.5)",
    "&:hover": {
      boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.5)",
    },
  },
  containerCriteria: {
    padding: 0,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    backgroundColor: "#D6DADB",
    border: "solid 1px #e9ecef",
    textAlign: "center",
    alignItems: "center",
    display: "grid",
  },

  containerScore: {
    padding: 0,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    border: "solid 1px #e9ecef",
    textAlign: "center",
    display: "grid",
  },
  containerAverage: {
    padding: 0,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    border: "solid 1px #e9ecef",
    textAlign: "center",
    display: "grid",
    backgroundColor: "#FAFAFA",
  },
};

const height = window.innerHeight;
const width = window.innerWidth;

const ReportCardBreakdownComponent = (props) => {
  const [t] = useTranslation(["global", "reportCards"]);
  const [reportCardInfo, setReportCardInfo] = useState({});
  const [hasDevelopmentAreas, setHasDevelopmentAreas] = useState(false);
  const [periods, setPeriods] = useState([]);
  const [widthForPeriod, setWidthForPeriod] = useState(80); //TODO: pendiente de evaluar el minimo para etos periodos
  const [criterias, setCriterias] = useState([]);
  const [widthForCriteria, setWidthForCriteria] = useState(100); //
  const [heightForPeriod, setHeightForPeriod] = useState(68);
  const [availablePartials, setAvailablePartials] = useState(false);
  const [visiblePopover, setVisiblePopover] = useState({
    show: false,
    id: null,
  });
  const [loading, setLoading] = useState(true);
  const [isLoadingScores, setIsLoadingScores] = useState(true);

  const [dinamicHeightReport, setDinamicHeighReport] = useState({
    maxHeight: height - 400 + "px",
    overflowY: "auto",
    border: "solid 1px #e9ecef",
    marginLeft: 0,
    marginRight: 0,
  });

  /* States for mobile mode */
  const [periodSelected, setPeriodSelected] = useState(null);
  const [partialSelectedMovil, setPartialSelectedMovil] = useState("all");
  const [showModalComments, setShowModalComments] = useState(false);
  /* States for mobile mode */

  useEffect(() => {
    getReportCard();
  }, []);

  // monitorear el ancho de pantalla con useEffect
  useEffect(() => {
    const observerResize = () => {
      handleResize();
      let dinamicHeight = { ...dinamicHeightReport };
      dinamicHeight.maxHeight = window.innerHeight - 400 + "px";
      setDinamicHeighReport(dinamicHeight);
    };
    handleResize();
    window.addEventListener("resize", observerResize);
    return () => {
      window.removeEventListener("resize", observerResize);
    };
  }, [periods, criterias]);

  const handleResize = () => {
    const minWidth = 104;
    const widthScore = 100;
    const containerPeriods =
      width <= 767
        ? document.getElementById("containerPeriodsMovil")
        : document.getElementById("containerPeriods");
    if (!containerPeriods) {
      setTimeout(() => {
        handleResize();
      }, 500);
      return;
    }
    const containerPeriodsWidth = containerPeriods.offsetWidth - widthScore;

    const widthPeriod = containerPeriodsWidth / periods.length;
    let newSizePeriod = widthPeriod > minWidth ? widthPeriod : minWidth;
    if (width <= 767) {
      newSizePeriod = document.getElementById(
        "containerPeriodsMovil"
      ).offsetWidth;
    }

    let minWidthCriteria = 60; //valor minimo soportado para cada criterio
    let widthCriteriaInitial = 60;

    if (periods.length > 0) {
      const criteriaList = periods[0].grading_criteria;
      const totalItelms = criteriaList.length + 2;
      widthCriteriaInitial = (newSizePeriod - 2) / totalItelms;
    }

    if (widthCriteriaInitial < minWidthCriteria) {
      const totalItelms = criterias.length + 2;

      newSizePeriod = minWidthCriteria * totalItelms;
      widthCriteriaInitial = minWidthCriteria - 1;
    }

    // algun periodo tiene parciales?
    const hasPartials = periods.some((period) =>
      period.grading_criteria.some((criteria) => criteria.partials.length > 0)
    );
    if (hasPartials && !(width <= 767)) {
      setHeightForPeriod(99);
    } else if (width <= 767) {
      const periodWithPartial = periodSelected?.grading_criteria.some(
        (criteria) => criteria.partials.length > 0
      );
      setHeightForPeriod(periodWithPartial ? 93 : 68);
    }
    setWidthForPeriod(newSizePeriod);
    setWidthForCriteria(widthCriteriaInitial);
    setTimeout(() => {
      setIsLoadingScores(false);
    }, 500);
    return;
  };

  const popoverComment = (comment) => {
    return (
      <Popover id="scoreComment">
        <Popover.Header>{reportCardInfo.student_name}</Popover.Header>
        <Popover.Body>{comment}</Popover.Body>
      </Popover>
    );
  };

  /**
   * Function to manage the click on the popover
   * @param {bool} show
   * @param {int} id
   * @returns
   */
  const handlePopoverClick = (show, id) => {
    if (!show) {
      if (visiblePopover.show && visiblePopover.id != id) {
        return;
      }
    }
    let popover = { ...visiblePopover };
    popover.show = show;
    popover.id = id;
    if (width <= 767) {
      setShowModalComments(show);
    } else {
      setVisiblePopover(popover);
    }
  };

  const getReportCard = () => {
    const payload = {
      student_id: props.studentId,
      organization_school_level_cycle_id: props.schoolLevelCycleId,
    };
    GetReportCardDetailedView(payload)
      .then((response) => {
        if (response.status) {
          let data = response.data;
          // remover los elementos que sean de tipo class y su propiedad belongs_class sea 0

          // agregar propiedad parcial seleccionado a todas las clases que tengan parciales
          data.elements.forEach((element) => {
            if (element.type === "class") {
              if (element.belongs_class === 0) {
                element.remove = true;
                return;
              }
              element.periods.forEach((period) => {
                period.selectedPartial = "all"; //criteria.partials[0];
              });
            } else if (element.type === "development_area") {
              element.classes.forEach((classElement) => {
                if (classElement.belongs_class === 0) {
                  classElement.remove = true;
                  return;
                }
                classElement.periods.forEach((period) => {
                  period.selectedPartial = "all";
                });
              });
            }
          });
          // remover las clases de los elements y dentro de los development_area que tengan remove
          data.elements.forEach((element) => {
            if (element.type === "development_area") {
              element.classes = element.classes.filter(
                (classElement) => !classElement.remove
              );
            }
          });
          data.elements = data.elements.filter((element) => !element.remove);

          setReportCardInfo(data);
          //buscar un elemento de tipo area de desarrollo
          const developmentAreaElement = data.elements.find(
            (element) => element.type === "development_area"
          );
          if (developmentAreaElement) setHasDevelopmentAreas(true);
          const classElement = data.elements.find(
            (element) => element.type === "class"
          );
          //obtener los peridos de la claseElement
          let periodsClass = [];
          if (classElement) {
            periodsClass = classElement.periods;
          } else {
            //obtener los peridos de la claseElement
            if (developmentAreaElement) {
              periodsClass = developmentAreaElement.classes[0].periods;
            }
          }
          periodsClass.forEach((period, index) => {
            period.position = index + 1;
          });
          setPeriods(periodsClass);
          setPeriodSelected(periodsClass[0]);
          // obtener los criterios
          const criteriaList = periodsClass[0].grading_criteria;

          setCriterias(criteriaList);
          // setWidthForCriteria(100 / periodsClass.length);

          // verificar si al menos un criterio tiene parciales
          let hasPartials = periodsClass.some((period) =>
            period.grading_criteria.some(
              (criteria) => criteria.partials.length > 0
            )
          );
          if (width <= 767) {
            hasPartials = periodsClass[0].grading_criteria.some(
              (criteria) => criteria.partials.length > 0
            );
          }
          setAvailablePartials(hasPartials);
        }
      })
      .finally(() => {
        setLoading(false);
        handleResize();
      });
  };

  const handlePartialChange = (periodId, partial) => {
    let periodsCopy = [...periods];
    periodsCopy.forEach((period) => {
      if (period.id == periodId) {
        period.selectedPartial = partial;
      }
      if (partial.id == "all") {
        period.selectedPartial = "all";
      }
    });
    setPeriods(periodsCopy);
  };

  const scoreByFilters = (period, criteria, movilView = false) => {
    const copyPeriods = [...periods];
    if (copyPeriods.length === 0) return 0;

    let partialSelected = copyPeriods.find(
      (periodItem) => periodItem.id == period.id
    ).selectedPartial;

    if (movilView) {
      partialSelected = partialSelectedMovil;
    }
    if (partialSelected === "all") {
      const criteriaFocus = period.grading_criteria.find(
        (criteriaItem) => criteriaItem.id == criteria.id
      );
      return criteriaFocus.score;
    } else {
      const criteriaFocus = period.grading_criteria.find(
        (criteriaItem) => criteriaItem.id == criteria.id
      );
      const partialFocus = criteriaFocus.partials.find(
        (partial) => partial.id == partialSelected.id
      );
      return partialFocus.score;
    }
  };

  // calificaiones vista movil
  const headerPeriodForMovilView = () => {
    return (
      <div
        style={{
          ...customStyles.columnPeriods,
          width: "60%",
          // padding: 0,
          overflowX: "clip",
          // paddingLeft: 0,
        }}
        id="containerPeriodsMovil"
      >
        {/* periods */}
        <Row
          style={{
            height: "30px",
            textAlign: "center",
            alignItems: "center",
            borderBottom: "solid 1px #e9ecef",
          }}
        >
          <Dropdown className="noCaretDd" style={{ padding: 0 }}>
            <Dropdown.Toggle
              id="dropdownReportCards"
              variant="outline-secondary"
              style={{
                width: "inherit",
                border: "none",
                boxShadow: "none",
                borderRadius: "0",
              }}
            >
              {t("reportCards:reportCardBasic.period") +
                " " +
                periodSelected.position}
              <span>
                <i
                  className="bi bi-chevron-down "
                  style={{ marginLeft: "5px" }}
                />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu
              style={{
                width:
                  document.getElementById("dropdownReportCards")?.offsetWidth +
                  "px",
                borderRadius: "0",
              }}
            >
              {periods.map((period, index) => {
                return (
                  <Dropdown.Item
                    key={index}
                    onClick={() => {
                      const hasPartials = period.grading_criteria.some(
                        (criteria) => criteria.partials.length > 0
                      );
                      setPartialSelectedMovil("all");
                      setHeightForPeriod(hasPartials ? 93 : 68);
                      setAvailablePartials(hasPartials);
                      setPeriodSelected(period);
                    }}
                  >
                    {t("reportCards:reportCardBasic.period") +
                      " " +
                      period.position}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </Row>

        {/* partials */}
        {availablePartials && (
          <Row
            style={{
              height: "30px",
              textAlign: "center",
              borderBottom: "solid 1px #e9ecef",
            }}
          >
            <Dropdown className="noCaretDd" style={{ padding: 0 }}>
              <Dropdown.Toggle
                id="dropdownReportCards"
                variant="outline-secondary"
                style={{
                  width: "inherit",
                  border: "none",
                  boxShadow: "none",
                  borderRadius: "0",
                }}
              >
                {partialSelectedMovil && partialSelectedMovil != "all"
                  ? t("reportCards:reportCardBreakdown.partial") +
                    " " +
                    partialSelectedMovil.position
                  : t("reportCards:reportCardBreakdown.allPeriods")}
                <span>
                  <i
                    className="bi bi-chevron-down"
                    style={{ marginLeft: "5px" }}
                  />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu
                style={{
                  width:
                    document.getElementById("dropdownReportCards")
                      ?.offsetWidth + "px",
                  borderRadius: "0",
                }}
              >
                <Dropdown.Item
                  onClick={() => {
                    setPartialSelectedMovil("all");
                  }}
                >
                  {t("reportCards:reportCardBreakdown.allPeriods")}
                </Dropdown.Item>
                {periodSelected.grading_criteria[0].partials.map(
                  (partial, indexPartial) => {
                    return (
                      <Dropdown.Item
                        key={indexPartial}
                        value={partial.id}
                        onClick={() => {
                          partial.position = indexPartial + 1;
                          setPartialSelectedMovil(partial);
                          periodSelected.selectedPartial = partial;
                          setPeriodSelected(periodSelected);
                        }}
                      >
                        {t("reportCards:reportCardBreakdown.partial") +
                          " " +
                          (indexPartial + 1)}
                      </Dropdown.Item>
                    );
                  }
                )}
              </Dropdown.Menu>
            </Dropdown>
          </Row>
        )}
        {/* calificaiones */}
        <div
          id="containerCriteriasMovil"
          style={{
            overflowX: "auto",
            overflowY: "hidden",
            marginLeft: "-12px",
            width:
              document.getElementById("dropdownReportCards")?.offsetWidth +
              12 +
              "px",
          }}
        >
          <Row
            style={{
              height: "38px",
              // paddingLeft: "11px",
              // paddingRight: "9px",
              width: "max-content",
              marginLeft: "0",
            }}
          >
            {periodSelected.grading_criteria.map((criteria, index) => {
              return (
                <div
                  key={index}
                  style={{
                    ...customStyles.containerCriteria,
                    width: widthForCriteria + "px",
                  }}
                >
                  <b
                    style={{
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                  >
                    {criteria.name}
                  </b>
                  {criteria.is_averageable == 1 && (
                    <b>{criteria.percentage + "%"}</b>
                  )}
                </div>
              );
            })}
            {/* promedio y comenarios header */}
            <div
              style={{
                ...customStyles.containerCriteria,
                width: widthForCriteria + "px",
              }}
            >
              <b
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {t("reportCards:reportCardBasic.average")}
              </b>
            </div>
            <div
              style={{
                ...customStyles.containerCriteria,
                width: widthForCriteria + 4 + "px",

                borderRight: "solid 1px #D6DADB",
              }}
            >
              <b
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {t("reportCards:reportCardBreakdown.comments")}
              </b>
            </div>
          </Row>

          {/* Iterar elementos de boleta */}
          {reportCardInfo.elements.map((element, index) => {
            if (element.type === "class") {
              // buscar en la clase el perido seleccionado
              const periodClass = element.periods.find(
                (period) => period.id === periodSelected.id
              );
              const space = widthForPeriod;
              return (
                <Row
                  key={index}
                  style={{ width: "max-content", marginLeft: "0px" }}
                >
                  <div
                    className="px-0"
                    style={{
                      display: "block ruby",
                      height: "40px",
                      width: space + "px",
                    }}
                  >
                    <div
                      key={periodClass.id}
                      style={{
                        border: "solid 1px #e9ecef",
                        height: "40px",
                        width: widthForPeriod + "px",
                        maxWidth: widthForPeriod + "px",
                        borderRight: "solid 1px #e9ecef",
                        borderBottom: "solid 1px #e9ecef",
                      }}
                    >
                      <Row
                        style={{
                          height: "40px",
                          paddingLeft: "11px",
                          paddingRight: "10px",
                        }}
                      >
                        {periodClass.grading_criteria.map((criteria, index) => {
                          return (
                            <div
                              key={index}
                              style={{
                                ...customStyles.containerScore,
                                width: widthForCriteria + "px",
                                alignItems: "center",
                                borderTop: "none",
                                borderBottom: "none",
                              }}
                            >
                              {scoreByFilters(periodClass, criteria, true)}
                            </div>
                          );
                        })}

                        {/* Pomedio y comentarios valores */}
                        <div
                          style={{
                            ...customStyles.containerAverage,
                            width: widthForCriteria + "px",
                            alignItems: "center",
                            borderTop: "none",
                            borderBottom: "solid 2px #e9ecef",
                          }}
                        >
                          {periodClass.score}
                        </div>
                        <div
                          style={{
                            ...customStyles.containerScore,
                            width: widthForCriteria + "px",
                            alignItems: "center",
                            borderRight: "none",
                            borderTop: "none",
                            borderBottom: "solid 1px #e9ecef",
                          }}
                        >
                          <b
                            style={{
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                          >
                            <i
                              className="bi bi-info-circle-fill"
                              style={{
                                color: "#ea2c54",
                                fontSize: "18px",
                                opacity: periodClass.comments ? 1 : 0.5,
                              }}
                              onClick={() => {
                                periodClass.comments
                                  ? setShowModalComments(periodClass.comments)
                                  : null;
                              }}
                            />
                          </b>
                        </div>
                      </Row>
                    </div>
                  </div>
                  {/* TODO: mover esto al blqoue de promedios al final */}
                  {/* <div
                    style={{
                      width: "100px",
                      height: "40px",
                      alignItems: "center",
                      border: "solid 1px #e9ecef",
                      backgroundColor: "#FAFAFA",
                      textAlign: "center",
                      display: "grid",
                    }}
                  >
                    {element.average}
                  </div> */}
                </Row>
              );
            }

            if (element.type === "development_area") {
              const numberOfClasses = element.classes.length;
              const space = widthForPeriod;
              return (
                <Row
                  key={index}
                  style={{ width: "max-content", marginLeft: "0px" }}
                >
                  <div
                    className="px-0"
                    style={{
                      height: 40 * numberOfClasses + "px",
                      width: space + "px",
                    }}
                  >
                    {element.classes.map((singleClass, c_index) => {
                      const periodClass = singleClass.periods.find(
                        (period) => period.id === periodSelected.id
                      );
                      return (
                        <Row
                          className="px-0 mx-0"
                          key={c_index}
                          style={{
                            display: "block ruby",
                            height: "40px",
                          }}
                        >
                          <div
                            key={periodClass.id}
                            className="px-0"
                            style={{
                              maxWidth: widthForPeriod + "px",
                              height: "40px",
                              display: "flex",
                              alignItems: "center",
                              borderRight: "solid 1px #e9ecef",
                              borderBottom: "solid 1px #e9ecef",
                            }}
                          >
                            <Row
                              style={{
                                height: "40px",
                                paddingLeft: "12px",
                                // paddingRight: "10px",
                                // textAlign: "center",
                                // display: "grid",
                              }}
                            >
                              {periodClass.grading_criteria.map(
                                (criteria, index) => {
                                  return (
                                    <div
                                      key={index}
                                      style={{
                                        ...customStyles.containerScore,
                                        width: widthForCriteria + "px",
                                        alignItems: "center",
                                      }}
                                    >
                                      {scoreByFilters(
                                        periodClass,
                                        criteria,
                                        true
                                      )}
                                    </div>
                                  );
                                }
                              )}

                              {/* Pomedio y comentarios valores */}
                              <div
                                style={{
                                  ...customStyles.containerAverage,
                                  width: widthForCriteria + "px",
                                  alignItems: "center",
                                }}
                              >
                                {periodClass.score}
                              </div>
                              <div
                                style={{
                                  ...customStyles.containerScore, //FIXME: revisar estilos para controlar bordos
                                  width: widthForCriteria + 5 + "px",
                                  alignItems: "center",
                                  // borderBottom: "solid 1px #e9ecef",
                                  borderRight: "none",
                                }}
                              >
                                <b
                                  style={{
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                  }}
                                >
                                  <i
                                    className="bi bi-info-circle-fill"
                                    style={{
                                      color: "#ea2c54",
                                      fontSize: "18px",
                                      opacity: periodClass.comments ? 1 : 0.5,
                                    }}
                                    onClick={() => {
                                      periodClass.comments
                                        ? setShowModalComments(
                                            periodClass.comments
                                          )
                                        : null;
                                    }}
                                  />
                                </b>
                              </div>
                            </Row>
                          </div>
                        </Row>
                      );
                    })}
                  </div>
                  {/* TODO: mover esto al blqoue de promedios al final */}
                  {/* <div
                    style={{
                      width: "100px",
                      height: 40 * numberOfClasses + "px",
                      alignItems: "center",
                      border: "solid 1px #e9ecef",
                      backgroundColor: "#FAFAFA",
                      textAlign: "center",
                      display: "grid",
                    }}
                  >
                    {element.average}
                  </div> */}
                </Row>
              );
            }

            if (element.type === "average") {
              const periodAverage = element.periods.find(
                (period) => period.id === periodSelected.id
              );
              return (
                <Row
                  key={index}
                  style={{ width: "max-content", marginLeft: "0px" }}
                >
                  <div
                    className="ps-0"
                    style={{
                      display: "block ruby",
                      height: "40px",
                      width: widthForPeriod + "px",
                    }}
                  >
                    <div
                      key={periodAverage.id}
                      style={{
                        border: "solid 1px #e9ecef",
                        height: "40px",
                        width: widthForPeriod + "px",
                        maxWidth: widthForPeriod + "px",
                      }}
                    >
                      <Row
                        style={{
                          height: "40px",
                          paddingLeft: "11px",
                          paddingRight: "10px",
                          // textAlign: "center",
                          // display: "grid",
                        }}
                      >
                        {periodAverage.grading_criteria.map(
                          (criteria, index) => {
                            return (
                              <div
                                key={index}
                                style={{
                                  ...customStyles.containerScore,
                                  width: widthForCriteria + "px",
                                  alignItems: "center",
                                  borderTop: "none",
                                  borderBottom: "none",
                                }}
                              >
                                <b></b>
                              </div>
                            );
                          }
                        )}

                        {/* Pomedio y comentarios valores */}
                        <div
                          style={{
                            ...customStyles.containerAverage,
                            width: widthForCriteria + "px",
                            alignItems: "center",
                            borderTop: "none",
                            borderBottom: "solid 2px #e9ecef",
                          }}
                        >
                          <b
                            style={{
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                          >
                            {periodAverage.score}
                          </b>
                        </div>
                        <div
                          style={{
                            ...customStyles.containerScore,
                            width: widthForCriteria + "px",
                            alignItems: "center",
                            borderRight: "none",
                            borderTop: "none",
                            borderBottom: "solid 1px #e9ecef",
                          }}
                        >
                          <b
                            style={{
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                          ></b>
                        </div>
                      </Row>
                    </div>
                  </div>
                  {/* TODO: revisar en las dudas que hacer con esto*/}
                  {/* <div
                    style={{
                      width: "100px",
                      height: "40px",
                      display: "grid",
                      alignItems: "center",
                      border: "solid 1px #e9ecef",
                      backgroundColor: "#FAFAFA",
                      textAlign: "center",
                    }}
                  >
                    <b> {element.total_average}</b>
                  </div> */}
                </Row>
              );
            }
          })}
        </div>
      </div>
    );
  };

  /* render LazyLoading */
  if (loading) {
    return (
      <Row style={{ padding: "13px" }}>
        <Col
          xxl={12}
          xl={12}
          lg={12}
          xs={12}
          style={customStyles.reportCardBase}
        >
          <div>
            <Row className="table-lazyLoading mt-4">
              <Col xxl={5} xl={5} lg={5} xs={12}>
                <div
                  style={{
                    width: "90px",
                    height: "90px",
                    display: "inline-block",
                  }}
                ></div>
                <div
                  style={{
                    width: "200px",
                    height: "20px",
                    display: "inline-block",
                    bottom: "69px",
                    marginLeft: "20px",
                  }}
                ></div>
              </Col>
              <Col xxl={7} xl={7} lg={7} xs={12} className="float-end">
                <div
                  style={{
                    width: "20%",
                    height: "20px",
                    display: "inline-block",
                    marginLeft: "20px",
                  }}
                ></div>
                <div
                  style={{
                    width: "20%",
                    height: "20px",
                    display: "inline-block",
                    marginLeft: "20px",
                  }}
                ></div>
                <div
                  className="float-end"
                  style={{
                    width: "20%",
                    height: "20px",
                    display: "inline-block",
                    marginLeft: "20px",
                  }}
                ></div>
                <div
                  className="float-end"
                  style={{
                    width: "20%",
                    height: "20px",
                    display: "inline-block",
                    marginLeft: "20px",
                  }}
                ></div>
              </Col>
            </Row>
            <Row className="table-lazyLoading mt-4">
              <Col md={12}>
                <div style={customStyles.lazy}></div>
                <div style={customStyles.lazy} className="mt-4"></div>
                <div style={customStyles.lazy} className="mt-4"></div>
                <div style={customStyles.lazy} className="mt-4"></div>
                <div style={customStyles.lazy} className="mt-4"></div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    );
  }

  /* Render Data */
  if (!loading) {
    return (
      <>
        <Row style={{ padding: "13px" }}>
          <Col
            xxl={12}
            xl={12}
            lg={12}
            xs={12}
            style={
              width <= 767
                ? customStyles.reportCardBaseMovil
                : customStyles.reportCardBase
            }
          >
            {/* Head ReportCard */}
            <Row className="mb-4" style={{ marginLeft: 0, marginRight: 0 }}>
              <Col md={5} sm={12} xs={12} className="ps-0">
                <Row>
                  <div
                    style={{
                      width: width <= 767 ? "30%" : "max-content",
                      display: "inline-block",
                    }}
                  >
                    <img
                      src={reportCardInfo.organization_logo_url}
                      alt="logo"
                      style={{ maxWidth: "90px" }}
                    />
                  </div>

                  <div
                    style={{
                      width: width <= 767 ? "70%" : "max-content",
                      display: "inline-block",
                    }}
                  >
                    <Row>
                      <Col md={12}>
                        <h6>
                          <b>{reportCardInfo.organization_name}</b>
                        </h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>{reportCardInfo.student_name}</Col>
                    </Row>
                  </div>
                </Row>
              </Col>
              <Col md={7} xs={12} className="pe-0">
                <Row>
                  <Col md={4} sm={12} xs={12}>
                    <b>
                      {t("reportCards:reportCardBasic.schoolCycle") + " :"}{" "}
                    </b>
                    {reportCardInfo.school_cycle_name}
                  </Col>
                  <Col md={4} xs={6}>
                    <b>{t("reportCards:reportCardBasic.schooling") + " :"} </b>
                    {reportCardInfo.school_level_name}
                  </Col>
                  <Col md={2} xs={3}>
                    <b>{t("reportCards:reportCardBasic.grade") + " :"} </b>
                    {reportCardInfo.grade_level}
                  </Col>
                  <Col
                    md={2}
                    xs={3}
                    className="pe-md-0"
                    style={{ textAlign: "end" }}
                  >
                    <b>{t("reportCards:reportCardBasic.group") + " :"} </b>
                    {reportCardInfo.grade_group}
                  </Col>
                </Row>
              </Col>
            </Row>

            {isLoadingScores && (
              <Row className="center-flex">
                <Spinner animation="border" variant="primary" />
              </Row>
            )}

            {/* Table info */}
            <Row
              className={
                isLoadingScores ? "hidden-container" : "visible-container"
              }
              style={dinamicHeightReport}
            >
              {/* Columna de lateral izquierda - items verticales (classes, develppment area, average) */}
              <div
                style={
                  width <= 767 ? { width: "40%" } : customStyles.columnClasses
                }
              >
                <Row>
                  <div
                    className="align-middle"
                    style={{
                      border: "solid 1px #e9ecef",
                      height: availablePartials
                        ? `${
                            heightForPeriod + (criterias.length > 0 ? 5 : 35)
                          }px`
                        : `${
                            heightForPeriod - (criterias.length > 0 ? 0 : 30)
                          }px`,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {hasDevelopmentAreas &&
                      (width <= 767 ? (
                        <b>
                          {t(
                            "reportCards:reportCardBreakdown.developmentAreas"
                          )}
                        </b>
                      ) : (
                        <div
                          className="text-center"
                          style={{ width: "100%", textTransform: "uppercase" }}
                        >
                          <b>
                            <h6>
                              {t(
                                "reportCards:reportCardBasic.developmentAreas"
                              )}
                            </h6>
                          </b>
                        </div>
                      ))}
                  </div>
                </Row>

                {reportCardInfo.elements.map((element, index) => {
                  return (
                    <Row key={index}>
                      {element.type === "class" && (
                        <>
                          {hasDevelopmentAreas && (
                            <Col
                              md={3}
                              sm={3}
                              xs={3}
                              style={{
                                ...customStyles.verticalText,
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                              }}
                            ></Col>
                          )}
                          <Col
                            md={hasDevelopmentAreas ? 9 : 12}
                            sm={hasDevelopmentAreas ? 9 : 12}
                            xs={hasDevelopmentAreas ? 9 : 12}
                          >
                            <Row>
                              <div
                                className="align-middle text-break"
                                style={{
                                  border: "solid 1px #e9ecef",
                                  height: "40px",
                                  display: "flex", //"-webkit-box",
                                  "-webkit-line-clamp": "2",
                                  "-webkit-box-orient": "horizontal",
                                  overflow: "hidden",
                                  "text-overflow": "ellipsis",
                                  alignItems: "center",
                                }}
                              >
                                {element.name}
                              </div>
                            </Row>
                          </Col>
                        </>
                      )}
                      {element.type === "development_area" && (
                        <>
                          <Col
                            md={3}
                            sm={3}
                            xs={3}
                            style={{
                              ...customStyles.verticalText,
                              maxHeight: 40 * element.classes.length + "px",
                              // whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                          >
                            {element.name}
                          </Col>
                          <Col md={9} sm={9} xs={9}>
                            {element.classes.map((element, index) => {
                              return (
                                <Row key={index}>
                                  <div
                                    className="align-middle text-break"
                                    style={{
                                      border: "solid 1px #e9ecef",
                                      height: "40px",
                                      display: "flex",
                                      alignItems: "center",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {element.name}
                                  </div>
                                </Row>
                              );
                            })}
                          </Col>
                        </>
                      )}
                      {element.type === "average" && (
                        <div
                          style={{
                            border: "solid 1px #e9ecef",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <b>
                            {t(
                              "reportCards:reportCardBreakdown.generalAverage"
                            )}
                          </b>
                        </div>
                      )}
                    </Row>
                  );
                })}
              </div>

              {/* Periodos y calificaciones */}
              {width <= 767 ? (
                // vista movil
                headerPeriodForMovilView()
              ) : (
                // vista escritorio
                <div
                  style={{
                    ...customStyles.columnPeriods,
                  }}
                  id="containerPeriods"
                >
                  {/* periods, partials and criterias */}
                  <Row style={{ width: "max-content" }}>
                    <div
                      className="px-0"
                      style={{
                        height: `${
                          40 +
                          (criterias.length > 0 ? 28 : 0) +
                          (availablePartials ? 30 : 0)
                        }px`,
                        width: widthForPeriod * periods.length + "px",
                        display: "flex",
                      }}
                    >
                      {periods.map((period, index) => {
                        return (
                          <div
                            key={index}
                            style={{
                              width: widthForPeriod + "px",
                              border: "solid 1px #e9ecef",
                              height: `${
                                heightForPeriod -
                                (criterias.length > 0 ? 0 : 30)
                              }px`,
                            }}
                          >
                            {/* Periodo header */}
                            <Row
                              style={{
                                height: "30px",
                                textAlign: "center",
                                alignItems: "center",
                              }}
                            >
                              <b>
                                {t("reportCards:reportCardBasic.period") +
                                  " " +
                                  (index + 1)}
                              </b>
                            </Row>
                            {/* Selector de parciales */}
                            {availablePartials && (
                              <Row
                                style={{
                                  height: "35px",
                                  textAlign: "center",
                                  display: "flex",
                                }}
                              >
                                {/* add dropDown */}
                                {period.grading_criteria[0].partials.length >
                                  0 && (
                                  <Dropdown className="noCaretDd">
                                    <Dropdown.Toggle
                                      id="dropdownReportCards"
                                      variant="outline-secondary"
                                      style={{
                                        textAlign: "start",
                                        width: "150px",
                                      }}
                                    >
                                      {period.selectedPartial &&
                                      period.selectedPartial != "all"
                                        ? t(
                                            "reportCards:reportCardBreakdown.partial"
                                          ) +
                                          " " +
                                          period.selectedPartial.position
                                        : t(
                                            "reportCards:reportCardBreakdown.allPeriods"
                                          )}

                                      <i
                                        className="bi bi-chevron-down float-end"
                                        style={{
                                          marginRight: 0,
                                          marginLeft: "5px",
                                          marginTop: "1px",
                                        }}
                                      />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu
                                      style={{
                                        textAlign: "start",
                                        width: "150px",
                                        minWidth: "150px",
                                      }}
                                    >
                                      {/* mapear parciales */}
                                      <Dropdown.Item
                                        key={index}
                                        style={{ width: "145px" }}
                                        onClick={() => {
                                          const partial = {
                                            id: "all",
                                          };
                                          handlePartialChange(
                                            period.id,
                                            partial
                                          );
                                        }}
                                      >
                                        {t(
                                          "reportCards:reportCardBreakdown.allPeriods"
                                        )}
                                      </Dropdown.Item>
                                      {period.grading_criteria[0].partials.map(
                                        (partial, indexPartial) => {
                                          return (
                                            <Dropdown.Item
                                              key={indexPartial}
                                              value={partial.id}
                                              style={{ width: "145px" }}
                                              onClick={() => {
                                                partial.position =
                                                  indexPartial + 1;
                                                handlePartialChange(
                                                  period.id,
                                                  partial
                                                );
                                              }}
                                            >
                                              {t(
                                                "reportCards:reportCardBreakdown.partial"
                                              )}{" "}
                                              {indexPartial + 1}
                                            </Dropdown.Item>
                                          );
                                        }
                                      )}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                )}
                              </Row>
                            )}
                            <Row
                              style={{
                                height: "38px",
                                paddingLeft: "11px",
                                paddingRight: "9px",
                              }}
                            >
                              {period.grading_criteria.map(
                                (criteria, index) => {
                                  return (
                                    <div
                                      key={index}
                                      style={{
                                        ...customStyles.containerCriteria,
                                        width: widthForCriteria + "px",
                                      }}
                                    >
                                      <b
                                        style={{
                                          whiteSpace: "nowrap",
                                          textOverflow: "ellipsis",
                                          overflow: "hidden",
                                        }}
                                      >
                                        {criteria.name}
                                      </b>
                                      {criteria.is_averageable == 1 && (
                                        <b>{criteria.percentage + "%"}</b>
                                      )}
                                    </div>
                                  );
                                }
                              )}
                              {/* promedio y comenarios header */}
                              <div
                                style={{
                                  ...customStyles.containerCriteria,
                                  width: widthForCriteria + "px",
                                }}
                              >
                                <b
                                  style={{
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                  }}
                                >
                                  {t("reportCards:reportCardBasic.average")}
                                </b>
                              </div>
                              <div
                                style={{
                                  ...customStyles.containerCriteria,
                                  width: widthForCriteria + 4 + "px",

                                  borderRight: "solid 1px #D6DADB",
                                }}
                              >
                                <b
                                  style={{
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                  }}
                                >
                                  {t(
                                    "reportCards:reportCardBreakdown.comments"
                                  )}
                                </b>
                              </div>
                            </Row>
                          </div>
                        );
                      })}
                    </div>
                    {/* header promedio campos forativos */}
                    <div
                      style={{
                        border: "solid 1px #e9ecef",
                        height: availablePartials
                          ? heightForPeriod + 5 + "px"
                          : heightForPeriod - 30 + "px",
                        width: "100px",
                      }}
                    >
                      <Row
                        style={{ height: availablePartials ? "65px" : "30px" }}
                      >
                        <b> </b>
                      </Row>
                      <Row
                        style={{
                          height: "38px",
                          textAlign: "center",
                          alignItems: "center",
                          backgroundColor: "#D6DADB",
                          border: "solid 1px #e9ecef",
                        }}
                      >
                        <b>
                          {t(
                            "reportCards:reportCardBreakdown.averageDevelopmentAreas"
                          )}
                        </b>
                      </Row>
                    </div>
                  </Row>
                  {/* Iterar elementos de boleta */}
                  {reportCardInfo.elements.map((element, index) => {
                    if (element.type === "class") {
                      const numerOfperiods = element.periods.length;
                      const space = widthForPeriod * numerOfperiods;
                      return (
                        <Row key={index} style={{ width: "max-content" }}>
                          <div
                            className="px-0"
                            style={{
                              display: "flex",
                              height: "40px",
                              width: space + "px",
                            }}
                          >
                            {element.periods.map((period, index) => {
                              return (
                                <div
                                  key={index}
                                  style={{
                                    border: "solid 1px #e9ecef",
                                    height: "40px",
                                    width: widthForPeriod + "px",
                                    maxWidth: widthForPeriod + "px",
                                  }}
                                >
                                  <Row
                                    style={{
                                      height: "40px",
                                      paddingLeft: "11px",
                                      paddingRight: "10px",
                                    }}
                                  >
                                    {period.grading_criteria.map(
                                      (criteria, index) => {
                                        return (
                                          <div
                                            key={index}
                                            style={{
                                              ...customStyles.containerScore,
                                              width: widthForCriteria + "px",
                                              alignItems: "center",
                                            }}
                                          >
                                            {scoreByFilters(period, criteria)}
                                          </div>
                                        );
                                      }
                                    )}

                                    {/* Pomedio y comentarios valores */}
                                    <div
                                      style={{
                                        ...customStyles.containerAverage,
                                        width: widthForCriteria + "px",
                                        alignItems: "center",
                                      }}
                                    >
                                      {period.score}
                                    </div>
                                    <div
                                      style={{
                                        ...customStyles.containerScore,
                                        width: widthForCriteria + "px",
                                        alignItems: "center",
                                        borderRight: "none",
                                      }}
                                    >
                                      <b
                                        style={{
                                          whiteSpace: "nowrap",
                                          textOverflow: "ellipsis",
                                          overflow: "hidden",
                                        }}
                                      >
                                        <OverlayTrigger
                                          trigger="click"
                                          placement="top"
                                          overlay={popoverComment(
                                            period.comments
                                          )}
                                          show={
                                            visiblePopover.show &&
                                            visiblePopover.id ===
                                              period.comment_id
                                          }
                                          onToggle={(value) => {
                                            period.comments
                                              ? handlePopoverClick(
                                                  value,
                                                  period.comment_id
                                                )
                                              : null;
                                          }}
                                          rootClose
                                        >
                                          <i
                                            className="bi bi-info-circle-fill"
                                            style={{
                                              color: "#ea2c54",
                                              fontSize: "18px",
                                              opacity: period.comments
                                                ? 1
                                                : 0.5,
                                              cursor: period.comments
                                                ? "pointer"
                                                : "default",
                                            }}
                                          />
                                        </OverlayTrigger>
                                      </b>
                                    </div>
                                  </Row>
                                </div>
                              );
                            })}
                          </div>
                          <div
                            style={{
                              width: "100px",
                              height: "40px",
                              alignItems: "center",
                              border: "solid 1px #e9ecef",
                              backgroundColor: "#FAFAFA",
                              textAlign: "center",
                              display: "grid",
                            }}
                          >
                            {element.average}
                          </div>
                        </Row>
                      );
                    }

                    if (element.type === "development_area") {
                      const numerOfperiods = element.classes[0].periods.length;

                      const numberOfClasses = element.classes.length;
                      const space = widthForPeriod * numerOfperiods;
                      return (
                        <Row key={index} style={{ width: "max-content" }}>
                          <div
                            className="px-0"
                            style={{
                              height: 40 * numberOfClasses + "px",
                              width: space + "px",
                            }}
                          >
                            {element.classes.map((singleClass, c_index) => {
                              return (
                                <Row
                                  className="px-0 mx-0"
                                  key={c_index}
                                  style={{
                                    display: "flex",
                                    height: "40px",
                                  }}
                                >
                                  {singleClass.periods.map(
                                    (period, p_index) => {
                                      return (
                                        <div
                                          key={p_index}
                                          className="px-0"
                                          style={{
                                            maxWidth: widthForPeriod + "px",
                                            height: "40px",
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Row
                                            style={{
                                              height: "40px",
                                              paddingLeft: "12px",
                                              // paddingRight: "10px",
                                              // textAlign: "center",
                                              // display: "grid",
                                            }}
                                          >
                                            {period.grading_criteria.map(
                                              (criteria, index) => {
                                                return (
                                                  <div
                                                    key={index}
                                                    style={{
                                                      ...customStyles.containerScore,
                                                      width:
                                                        widthForCriteria + "px",
                                                      alignItems: "center",
                                                    }}
                                                  >
                                                    {scoreByFilters(
                                                      period,
                                                      criteria
                                                    )}
                                                  </div>
                                                );
                                              }
                                            )}

                                            {/* Pomedio y comentarios valores */}
                                            <div
                                              style={{
                                                ...customStyles.containerAverage,
                                                width: widthForCriteria + "px",
                                                alignItems: "center",
                                              }}
                                            >
                                              {period.score}
                                            </div>
                                            <div
                                              style={{
                                                ...customStyles.containerScore,
                                                width: widthForCriteria + "px",
                                                alignItems: "center",
                                                borderRight: "none",
                                              }}
                                            >
                                              <b
                                                style={{
                                                  whiteSpace: "nowrap",
                                                  textOverflow: "ellipsis",
                                                  overflow: "hidden",
                                                }}
                                              >
                                                <OverlayTrigger
                                                  trigger="click"
                                                  placement="top"
                                                  overlay={popoverComment(
                                                    period.comments
                                                  )}
                                                  show={
                                                    visiblePopover.show &&
                                                    visiblePopover.id ===
                                                      period.comment_id
                                                  }
                                                  onToggle={(value) => {
                                                    period.comments
                                                      ? handlePopoverClick(
                                                          value,
                                                          period.comment_id
                                                        )
                                                      : null;
                                                  }}
                                                  rootClose
                                                >
                                                  <i
                                                    className="bi bi-info-circle-fill"
                                                    style={{
                                                      color: "#ea2c54",
                                                      fontSize: "18px",
                                                      opacity: period.comments
                                                        ? 1
                                                        : 0.5,
                                                      cursor: period.comments
                                                        ? "pointer"
                                                        : "default",
                                                    }}
                                                  />
                                                </OverlayTrigger>
                                              </b>
                                            </div>
                                          </Row>
                                        </div>
                                      );
                                    }
                                  )}
                                </Row>
                              );
                            })}
                          </div>
                          <div
                            style={{
                              width: "100px",
                              height: 40 * numberOfClasses + "px",
                              alignItems: "center",
                              border: "solid 1px #e9ecef",
                              backgroundColor: "#FAFAFA",
                              textAlign: "center",
                              display: "grid",
                            }}
                          >
                            {element.average}
                          </div>
                        </Row>
                      );
                    }

                    if (element.type === "average") {
                      return (
                        <Row key={index} style={{ width: "max-content" }}>
                          <div
                            className="ps-0"
                            style={{
                              display: "contents",
                              height: "40px",
                              width:
                                widthForPeriod * element.periods.length + "px",
                            }}
                          >
                            {element.periods.map((period, index) => {
                              return (
                                <div
                                  key={index}
                                  style={{
                                    border: "solid 1px #e9ecef",
                                    height: "40px",
                                    width: widthForPeriod + "px",
                                    maxWidth: widthForPeriod + "px",
                                  }}
                                >
                                  <Row
                                    style={{
                                      height: "40px",
                                      paddingLeft: "11px",
                                      paddingRight: "10px",
                                      // textAlign: "center",
                                      // display: "grid",
                                    }}
                                  >
                                    {period.grading_criteria.map(
                                      (criteria, index) => {
                                        return (
                                          <div
                                            key={index}
                                            style={{
                                              ...customStyles.containerScore,
                                              width: widthForCriteria + "px",
                                              alignItems: "center",
                                            }}
                                          >
                                            <b></b>
                                          </div>
                                        );
                                      }
                                    )}

                                    {/* Pomedio y comentarios valores */}
                                    <div
                                      style={{
                                        ...customStyles.containerAverage,
                                        width: widthForCriteria + "px",
                                        alignItems: "center",
                                      }}
                                    >
                                      <b
                                        style={{
                                          whiteSpace: "nowrap",
                                          textOverflow: "ellipsis",
                                          overflow: "hidden",
                                        }}
                                      >
                                        {period.score}
                                      </b>
                                    </div>
                                    <div
                                      style={{
                                        ...customStyles.containerScore,
                                        width: widthForCriteria + "px",
                                        alignItems: "center",
                                        borderRight: "none",
                                      }}
                                    >
                                      <b
                                        style={{
                                          whiteSpace: "nowrap",
                                          textOverflow: "ellipsis",
                                          overflow: "hidden",
                                        }}
                                      ></b>
                                    </div>
                                  </Row>
                                </div>
                              );
                            })}
                          </div>
                          <div
                            style={{
                              width: "100px",
                              height: "40px",
                              display: "grid",
                              alignItems: "center",
                              border: "solid 1px #e9ecef",
                              backgroundColor: "#FAFAFA",
                              textAlign: "center",
                            }}
                          >
                            <b> {element.total_average}</b>
                          </div>
                        </Row>
                      );
                    }
                  })}
                </div>
              )}
            </Row>
          </Col>
        </Row>

        <Modal show={showModalComments} centered>
          <Modal.Header>
            <Modal.Title>
              {/* student name */}
              {reportCardInfo.student_name}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ textAlign: "justify" }}>{showModalComments}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{ width: "100%" }}
              variant="secondary"
              onClick={() => setShowModalComments(false)}
            >
              {t("global:buttons.close")}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
};

export default ReportCardBreakdownComponent;
ReportCardBreakdownComponent.propTypes = {
  studentId: PropTypes.string,
  schoolLevelCycleId: PropTypes.string,
};
