import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Modal, Row, Col, Button } from "react-bootstrap";

import { RemoveConcept } from "../../../api/Products";

import ModalSuccess from "../../../components/global/modals/ModalSuccess";
import TextLoading from "../../../components/global/TextLoading";

export default function DeleteConceptModal(props) {
  const [t] = useTranslation(["global"]);
  const [showModal, setShowModal] = useState(props.showDeleteConceptModal);
  const [isRemovingConcept, setIsRemovingConcept] = useState(false);
  const [showModalSuccess, setShowModalSuccess] = useState(false);

  /**
   * Description: Remove concept
   */
  const removeConcept = () => {
    const payload = {
      id: props.conceptToEdit.id,
    };
    setIsRemovingConcept(true);
    RemoveConcept(payload)
      .then((response) => {
        if (response.data) {
          setShowModal(false);
          setShowModalSuccess(true);
          const conceptId = payload.id;
          // Update main categories list
          props.updateCategories((prev) =>
            updateCategoryConcepts(prev, conceptId)
          );
          // Remove concept from list by id
          const conceptsUpdated = props.concepts.filter(
            (concept) => concept.id != payload.id
          );
          props.setConcepts(conceptsUpdated);
          // Clean concept selected if is removed
          if (props.conceptSelected?.id == payload.id) {
            props.newConceptSelected(null);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsRemovingConcept(false);
      });
  };

  /**
   * Description: Function to update categories list
   * @param {array} prevCategories
   * @param {string} conceptId
   * @return {array} categories list updated
   */
  const updateCategoryConcepts = (prevCategories, conceptId) => {
    const categories = [...prevCategories];
    // Find position in the category list
    const categoryPos = categories.findIndex(
      (cat) => cat.id == props.categoryIdSelected
    );
    if (categoryPos === -1) return prevCategories;
    // Find position in the concept list
    const category = categories[categoryPos];
    const conceptPos = category.product_concepts.findIndex(
      (con) => con.id == conceptId
    );
    // Remove element if is found
    if (conceptPos !== -1) {
      category.product_concepts.splice(conceptPos, 1);
    }
    return categories;
  };

  return (
    <>
      {showModal && (
        <Modal
          id="deleteConceptModal"
          show={showModal}
          aria-labelledby="contained-modal-title-md"
        >
          <Modal.Header className="modal-header">
            <Modal.Title className="modal-title text-center">
              {t("products:modalRemoveConcept.deleteConcept")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-body mbottom10">
            <Row>
              <Col md={12} style={{ textAlign: "justify" }}>
                {t("products:modalRemoveConcept.deleteConceptDescription")}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <Button
                variant="outline-secondary"
                onClick={props.onHide}
                disabled={isRemovingConcept}
              >
                {t("global:buttons.cancel")}
              </Button>

              <Button
                variant="primary"
                onClick={removeConcept}
                disabled={isRemovingConcept}
              >
                {isRemovingConcept ? (
                  <TextLoading
                    text={t("global:buttons.deleting2")}
                    variant="white"
                  />
                ) : (
                  t("global:buttons.eliminate")
                )}
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
      {/** Modal Success */}
      {showModalSuccess && (
        <ModalSuccess
          showModalSuccess={showModalSuccess}
          title={t("products:modalRemoveConcept.deletedConcept")}
          message={t("products:modalRemoveConcept.deletedConceptDescription")}
          fnAcceptButton={props.onHide}
        />
      )}
    </>
  );
}

DeleteConceptModal.propTypes = {
  showDeleteConceptModal: PropTypes.bool,
  onHide: PropTypes.func,
  updateCategories: PropTypes.func.isRequired,
  categoryIdSelected: PropTypes.object,
  conceptToEdit: PropTypes.object,
  concepts: PropTypes.array,
  setConcepts: PropTypes.func,
  conceptSelected: PropTypes.object,
  newConceptSelected: PropTypes.func,
};
