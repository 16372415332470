import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { GetReportCard } from "../../api/ReportCards";
import { decodeValue } from "../global/GlobalTools";

const customStyles = {
  reportCardBase: {
    backgroundColor: "#ffffff",
    padding: "40px",
    marginBottom: "20px",
  },
  columnClasses: {
    width: "35%",
  },
  columnPeriods: {
    width: "65%",
    overflowX: "auto",
  },
  columnScore: {
    // width: "25%",
    // minWidth: "80px",
    border: "solid 1px #e9ecef",
    borderRight: "none",
    borderLeft: "none",
  },
  columnScoreRed: {
    backgroundColor: "#FFF0F3",
    border: "solid 1px #e9ecef",
    borderRight: "none",
    borderLeft: "none",
  },
  verticalText: {
    writingMode: "vertical-rl",
    transform: "rotate(180deg)",
    textAlign: "center",
    borderRight: "solid 1px #e9ecef",
    borderTop: "solid 1px #e9ecef",
    borderBottom: "solid 1px #e9ecef",
    // whiteSpace: "nowrap",
    // display: "-webkit-box",
    // "-webkit-line-clamp": "3",
    // alignItems: "center",
  },
  fillCaracter: {
    background:
      "linear-gradient(to right, black 50%, rgba(255, 255, 255, 0) 0%)",
    backgroundSize: "10px 1px" /* Ajusta el tamaño del carácter repetido */,
    backgroundRepeat: "repeat-x",
    color: "transparent" /* Hace que el texto sea invisible */,
    lineHeight: 3 /* Ajusta la altura de línea si es necesario */,
  },
  // crear estilo para boxShadow cuando se haga hover
  shadowRowHover: {
    boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.5)",
    "&:hover": {
      boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.5)",
    },
  },
};

const height = window.innerHeight;

const ReportCardComponent = (props) => {
  const [t] = useTranslation(["global", "reportCards"]);
  const { student_id, school_level_cycle } = useParams();
  const [periods, setPeriods] = useState([]);
  const [widthForPeriod, setWidthForPeriod] = useState(80);
  const [hasDevelopmentAreas, setHasDevelopmentAreas] = useState(false);
  const [reportCardInfo, setReportCardInfo] = useState();
  const [loading, setLoading] = useState(true);
  const [hasEvaluationCriteria, setHasEvaluationCriteria] = useState(false);
  const [dinamicHeightReport, setDinamicHeighReport] = useState({
    height: height - 400 + "px",
    overflowY: "auto",
  });

  useEffect(() => {
    getReportCard();
  }, []);

  // Monitor screen width with useEffect
  useEffect(() => {
    const observerResize = () => {
      handleResize();
      let dinamicHeight = { ...dinamicHeightReport };
      dinamicHeight.height = window.innerHeight - 400 + "px";
      setDinamicHeighReport(dinamicHeight);
    };
    handleResize();
    window.addEventListener("resize", observerResize);
    return () => {
      window.removeEventListener("resize", observerResize);
    };
  }, [periods]);

  const handleResize = () => {
    const minWidth = 104;
    const widthScore = 100;
    const containerPeriods = document.getElementById("containerPeriods");
    if (!containerPeriods) {
      setTimeout(() => {
        handleResize();
      }, 500);
      return;
    }
    const containerPeriodsWidth = containerPeriods.offsetWidth - widthScore;

    const widthPeriod = containerPeriodsWidth / periods.length;

    const newSize = widthPeriod > minWidth ? widthPeriod : minWidth;

    setWidthForPeriod(newSize);
    return;
  };

  const getReportCard = () => {
    const studentId = decodeValue(student_id);
    const schoolLevelCycle = decodeValue(school_level_cycle);
    const payload = {
      student_id: studentId,
      organization_school_level_cycle_id: schoolLevelCycle,
    };
    GetReportCard(payload)
      .then((response) => {
        const reportCardResponse = response.data;
        setHasEvaluationCriteria(
          reportCardResponse.has_evaluation_criteria == 1
        );
        if (response.status) {
          reportCardResponse.elements.forEach((element) => {
            if (element.type === "class") {
              if (element.belongs_class === 0) {
                element.remove = true;
                return;
              }
            } else if (element.type === "development_area") {
              element.classes.forEach((classElement) => {
                if (classElement.belongs_class === 0) {
                  classElement.remove = true;
                  return;
                }
              });
            }
          });
          // remover las clases de los elements y dentro de los development_area que tengan remove
          reportCardResponse.elements.forEach((element) => {
            if (element.type === "development_area") {
              element.classes = element.classes.filter(
                (classElement) => !classElement.remove
              );
            }
          });
          reportCardResponse.elements = reportCardResponse.elements.filter(
            (element) => !element.remove
          );

          const classElement = reportCardResponse.elements.find(
            (element) => element.type === "class"
          );
          // Get the peridos of theElement class
          let periodsClass = [];
          // Search for an element of type development area
          const developmentAreaElement = reportCardResponse.elements.find(
            (element) => element.type === "development_area"
          );
          if (developmentAreaElement) setHasDevelopmentAreas(true);

          if (classElement) {
            periodsClass = classElement.periods;
          } else {
            // Get the peridos of theElement class
            if (developmentAreaElement) {
              periodsClass = developmentAreaElement.classes[0].periods;
            }
          }

          setReportCardInfo(reportCardResponse);
          props.setStudentName(reportCardResponse.student_name);
          setPeriods(periodsClass);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading) {
    return (
      <Row className="justify-content-md-center mt-5">
        <Col xxl={7} xl={7} lg={12} xs={12} style={customStyles.reportCardBase}>
          <div>
            <Row className="table-lazyLoading mt-4">
              <Col md={7} xxl={7} xl={7} lg={7} xs={12}>
                <div
                  style={{
                    width: "90px",
                    height: "90px",
                    display: "inline-block",
                  }}
                ></div>
                <div
                  style={{
                    width: "200px",
                    height: "20px",
                    display: "inline-block",
                    bottom: "69px",
                    marginLeft: "20px",
                  }}
                ></div>
              </Col>
              <Col md={5} xxl={5} xl={5} lg={5} xs={12}>
                <div
                  style={{
                    width: "200px",
                    height: "20px",
                    marginLeft: "20px",
                  }}
                ></div>
                <div
                  style={{
                    width: "200px",
                    height: "20px",
                    marginLeft: "20px",
                  }}
                ></div>
                <div
                  style={{
                    width: "200px",
                    height: "20px",
                    marginLeft: "20px",
                  }}
                ></div>
              </Col>
            </Row>
            <Row className="table-lazyLoading mt-4">
              <Col md={12}>
                <div style={customStyles.lazy}></div>
                <div style={customStyles.lazy} className="mt-4"></div>
                <div style={customStyles.lazy} className="mt-4"></div>
                <div style={customStyles.lazy} className="mt-4"></div>
                <div style={customStyles.lazy} className="mt-4"></div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    );
  }

  if (!loading) {
    return (
      <Row className="justify-content-md-center mt-5">
        <Col xxl={8} xl={8} lg={12} xs={12} style={customStyles.reportCardBase}>
          {/* Head ReportCard */}
          <Row className="mb-4">
            <Col md={8}>
              <Row>
                <div style={{ width: "max-content", display: "inline-block" }}>
                  <img
                    src={reportCardInfo.organization_logo_url}
                    alt="logo"
                    style={{ maxWidth: "90px" }}
                  />
                </div>
                <div style={{ width: "max-content", display: "inline-block" }}>
                  <Row>
                    <Col md={12}>
                      <h6>
                        <b>{reportCardInfo.organization_name}</b>
                      </h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>{reportCardInfo.student_name}</Col>
                  </Row>
                </div>
              </Row>
            </Col>
            <Col md={4}>
              <Row>
                <Col md={12}>
                  <b>{t("reportCards:reportCardBasic.schoolCycle") + " :"} </b>
                  {reportCardInfo.school_cycle_name}
                </Col>
                <Col md={12}>
                  <b>{t("reportCards:reportCardBasic.schooling") + " :"} </b>
                  {reportCardInfo.school_level_name}
                </Col>
                <Col md={12}>
                  <b>{t("reportCards:reportCardBasic.grade") + " :"} </b>
                  {reportCardInfo.grade_level}{" "}
                  <b>{t("reportCards:reportCardBasic.group") + " :"} </b>
                  {reportCardInfo.grade_group}
                </Col>
              </Row>
            </Col>
          </Row>
          {/* Table info */}
          <Row style={dinamicHeightReport}>
            {/* Columna de lateral izquierda (classes, develpment area, average) */}
            <div style={customStyles.columnClasses}>
              {hasDevelopmentAreas ? (
                <div className="text-center">
                  <b>{t("reportCards:reportCardBasic.developmentAreas")}</b>
                </div>
              ) : (
                <b style={{ height: "18px", display: "block" }}> </b>
              )}
              {reportCardInfo.elements.map((element, index) => {
                return (
                  <Row key={index}>
                    {element.type == "class" && (
                      <>
                        {hasDevelopmentAreas && (
                          <Col
                            md={3}
                            style={{
                              ...customStyles.verticalText,
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                          ></Col>
                        )}
                        <Col md={hasDevelopmentAreas ? 9 : 12}>
                          <Row>
                            <div
                              className="align-middle"
                              style={{
                                border: "solid 1px #e9ecef",
                                height: "40px",
                                //  display: "flex",
                                // alignItems: "center",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                paddingTop: "10px",
                              }}
                            >
                              {element.name}
                            </div>
                          </Row>
                        </Col>
                      </>
                    )}
                    {element.type == "development_area" && (
                      <>
                        <Col
                          md={3}
                          sm={3}
                          xs={3}
                          style={{
                            ...customStyles.verticalText,
                            maxHeight: 40 * element.classes.length + "px",
                            "-webkit-box-orient": "vertical",
                            overflow: "hidden",
                            "text-overflow": "ellipsis",
                            paddingLeft: 0,
                            paddingRight: "10px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {element.name}
                        </Col>
                        <Col md={9} sm={9} xs={9}>
                          {element.classes.map((element, index) => {
                            return (
                              <Row key={index}>
                                <div
                                  className="align-middle text-break"
                                  style={{
                                    border: "solid 1px #e9ecef",
                                    height: "40px",
                                    display: "flex",
                                    alignItems: "center",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                  }}
                                >
                                  {element.name}
                                </div>
                              </Row>
                            );
                          })}
                        </Col>
                      </>
                    )}
                    {element.type == "average" && (
                      <>
                        <div className="px-0" style={{ paddingTop: "20px" }}>
                          <div
                            style={{
                              //  ...customStyles.fillCaracter,
                              height: "20px",
                              display: "flex",
                              alignItems: "center",
                              overflow: "hidden",
                            }}
                          ></div>
                        </div>
                        <div
                          style={{
                            border: "solid 1px #e9ecef",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <b>{t("reportCards:reportCardBasic.average")}</b>
                        </div>
                      </>
                    )}
                  </Row>
                );
              })}
            </div>

            {/* Periodos y calificaciones */}
            <div
              style={{ ...customStyles.columnPeriods }}
              id="containerPeriods"
            >
              <Row style={{ width: "max-content" }}>
                <div
                  className="px-0"
                  style={{
                    height: "18px",
                    width: widthForPeriod * periods.length + "px",
                  }}
                >
                  <Row
                    className="px-0 mx-0"
                    style={{
                      display: "block ruby",
                      height: "18px",
                    }}
                  >
                    {periods.map((period, index) => {
                      return (
                        <div
                          key={index}
                          style={{
                            maxWidth: widthForPeriod + "px",
                          }}
                        >
                          <b>
                            {t("reportCards:reportCardBasic.period") +
                              " " +
                              (index + 1)}
                          </b>
                        </div>
                      );
                    })}
                  </Row>
                </div>
                <div style={{ width: "100px" }}>
                  <b>{t("reportCards:reportCardBasic.average")}</b>
                </div>
              </Row>
              {/* Iterar elementos de boleta */}
              {reportCardInfo.elements.map((element, index) => {
                const isEven = (num) => {
                  return num % 2 === 0;
                };

                const redRow = isEven(index + 1)
                  ? customStyles.columnScoreRed
                  : customStyles.columnScore;
                // Elemento de tipo campo formativo
                if (element.type == "development_area") {
                  const numerOfperiods = element.classes[0].periods.length;
                  const numberOfClasses = element.classes.length;
                  const space = widthForPeriod * numerOfperiods;
                  return (
                    <Row
                      style={{ width: "max-content" }}
                      className={hasEvaluationCriteria ? "externalShadow" : ""}
                      role={hasEvaluationCriteria ? "button" : null}
                      onClick={() => {
                        hasEvaluationCriteria
                          ? props.setView("breakdown")
                          : null;
                      }}
                    >
                      <div
                        className="px-0"
                        style={{
                          height: 40 * numberOfClasses + "px",
                          width: space + "px",
                        }}
                      >
                        {element.classes.map((singleClass, c_index) => {
                          return (
                            <Row
                              className="px-0 mx-0"
                              key={c_index}
                              style={{
                                display: "block ruby",
                                height: "40px",
                              }}
                            >
                              {singleClass.periods.map((period, p_index) => {
                                return (
                                  <div
                                    key={p_index}
                                    style={{
                                      ...redRow,
                                      maxWidth: widthForPeriod + "px",
                                      height: "40px",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {period.score}
                                  </div>
                                );
                              })}
                            </Row>
                          );
                        })}
                      </div>
                      <div
                        style={{
                          ...redRow,
                          width: "100px",
                          height: 40 * numberOfClasses + "px",
                          display: "flex",
                          alignItems: "center",
                          border: "solid 1px #e9ecef",
                          // backgroundColor: redRow ? "#FFF0F3" : "#ffffff",
                        }}
                      >
                        {element.average}
                      </div>
                    </Row>
                  );
                }
                // Elemento de tipo clase
                if (element.type == "class") {
                  const numerOfperiods = element.periods.length;
                  const space = widthForPeriod * numerOfperiods;
                  return (
                    <Row
                      key={index}
                      style={{ width: "max-content" }}
                      className={hasEvaluationCriteria ? "innerShadow" : ""}
                      role={hasEvaluationCriteria ? "button" : null}
                      onClick={() => {
                        hasEvaluationCriteria
                          ? props.setView("breakdown")
                          : null;
                      }}
                    >
                      <div
                        className="px-0"
                        style={{
                          height: "40px",
                          width: space + "px",
                        }}
                      >
                        <Row
                          className="px-0 mx-0"
                          style={{
                            display: "block ruby",
                            height: "40px",
                          }}
                        >
                          {element.periods.map((period, index) => {
                            return (
                              <div
                                key={index}
                                style={{
                                  ...redRow,
                                  maxWidth: widthForPeriod + "px",
                                  height: "40px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {period.score}
                              </div>
                            );
                          })}
                        </Row>
                      </div>
                      <div
                        style={{
                          ...redRow,
                          width: "100px",
                          height: "40px",
                          display: "flex",
                          alignItems: "center",
                          border: "solid 1px #e9ecef",
                        }}
                      >
                        {element.average}
                      </div>
                    </Row>
                  );
                }
                // Elemento de tipo promedio
                if (element.type == "average") {
                  return (
                    <>
                      <Row
                        key={index}
                        style={{
                          display: "block ruby",
                          paddingTop: "15px", //"20px",
                          width: "max-content",
                        }}
                      >
                        <div
                          style={{
                            //  ...customStyles.fillCaracter,
                            width: widthForPeriod * periods.length + 100 + "px",
                            height: "20px",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: 0,
                          }}
                        ></div>
                      </Row>
                      <Row key={index} style={{ width: "max-content" }}>
                        <div
                          className="px-0"
                          style={{
                            height: "18px",
                            width: widthForPeriod * periods.length + "px",
                          }}
                        >
                          <Row
                            className="px-0 mx-0"
                            style={{
                              display: "block ruby",
                              height: "18px",
                            }}
                          >
                            {element.periods.map((period, index) => {
                              return (
                                <div
                                  key={index}
                                  style={{
                                    ...redRow,
                                    maxWidth: widthForPeriod + "px",
                                    height: "40px",
                                    display: "flex",
                                    alignItems: "center",
                                    backgroundColor: redRow
                                      ? "#FFF0F3"
                                      : "#ffffff",
                                  }}
                                >
                                  <b>{period.score}</b>
                                </div>
                              );
                            })}
                          </Row>
                        </div>
                        <div
                          style={{
                            width: "100px",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                            border: "solid 1px #e9ecef",
                            backgroundColor: redRow ? "#FFF0F3" : "#ffffff",
                          }}
                        >
                          <b>{element.total_average}</b>
                        </div>
                      </Row>
                    </>
                  );
                }
              })}
            </div>
          </Row>
        </Col>
      </Row>
    );
  }
};

export default ReportCardComponent;

ReportCardComponent.propTypes = {
  setStudentName: PropTypes.func,
  setView: PropTypes.func,
};
