import React, { useContext, useState } from "react";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { MainContext } from "../../../App";

// Import API Services
import { SaveCategory } from "../../../api/Products";

// Import components
import ModalSuccess from "../../../components/global/modals/ModalSuccess";
import TextLoading from "../../../components/global/TextLoading";

// Set custom Styles
const customStyles = {
  iconsContainer: {
    height: "20vh",
    minHeight: "150px",
    overflowY: "scroll",
    overflowX: "hidden",
    padding: "20px 10px",
    border: "1px solid rgb(237, 237, 237)",
    borderRadius: "4px",
    scrollbarWidth: "thin",
    display: "inline-block",
  },
  icon: {
    display: "block",
    marginBottom: "5px",
  },
};

export default function ModalSaveCategory(props) {
  const prefix = process.env.REACT_APP_PREFIX;
  // Get data from local storage
  const organizationServices = JSON.parse(
    localStorage.getItem(`${prefix}Services`)
  );

  // Hooks
  const [t] = useTranslation(["products", "global"]);
  const { permissions } = useContext(MainContext);
  const [category, setCategory] = useState(
    props.categoryToEdit || {
      name: "",
      bank_account_id: "",
      icon_id: props.icons[0].id,
    }
  );
  const [activeBtn, setActiveBtn] = useState(!!props.categoryToEdit);
  const [showModal, setShowModal] = useState(props.show);
  const [showModalSuccess, setShowSuccessModal] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [errors, setErrors] = useState(false);
  const sapIsActive =
    organizationServices?.SAP?.active && permissions.sap_reports.edit;
  const [isValidCategoryName, setIsValidCategoryName] = useState(true);

  const changeValues = (event) => {
    const field = event.target.id;
    const value = event.target.value;
    let categoryUpdated = { ...category };
    // Update the category
    if (field == "bank_account_id") {
      const onlyNumbersAndLetters = /^[0-9a-zA-Z]+$/;
      if (!onlyNumbersAndLetters.test(value) && value != "") {
        return;
      } else {
        categoryUpdated[field] = value.replace(/\s+/g, "");
      }
    } else {
      categoryUpdated[field] = value;
    }
    setCategory(categoryUpdated);
    // Validate if the form is ready
    const formReady =
      categoryUpdated.name != "" && categoryUpdated.icon_id != "";
    setActiveBtn(formReady);
  };

  /**
   * Description: Method to save a category
   */
  const saveCategory = async () => {
    const payload = {
      name: category.name?.toUpperCase(),
      id: category.id,
      icon_id: category.icon_id,
      organization_id: localStorage.getItem(`cmOrganizationID${prefix}`),
      school_cycle_id: props.schoolCycle?.id,
    };
    if (sapIsActive) {
      payload.bank_account_id = category.bank_account_id;
    }
    setIsSaving(true);
    await SaveCategory(payload)
      .then((response) => {
        if (response.data) {
          setShowModal(false);
          setShowSuccessModal(true);
          // Update the category, if it exists else add it
          props.updateCategories((prev) => {
            let categories = [...prev];
            const category = response.data;
            if (payload.id) {
              const index = categories.findIndex((c) => c.id == category.id);
              category.icon_id = category.icon.id;
              categories[index] = category;
            } else {
              category.index = categories.length;
              category.icon_id = category.icon.id;
              category.product_concepts = [];
              categories.push(category);
            }
            props.updateCategorySelected(category, payload.id);
            return categories;
          });
        } else {
          setErrors(response);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  return (
    <>
      {showModal && (
        <Modal show={showModal} onHide={props.onHide} backdrop={"static"}>
          <Modal.Header>
            <Modal.Title>
              {t(
                category.id
                  ? "modalSaveCategory.editCategory"
                  : "modalSaveCategory.addCategory"
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={12}>
                {/* Category Name */}
                <Row>
                  <Col md={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        {t("modalSaveCategory.nameCategory")}
                      </Form.Label>
                      <Form.Control
                        id="name"
                        type="text"
                        className={
                          isValidCategoryName ? "well" : "errorValidation"
                        }
                        style={{ textTransform: "uppercase" }}
                        onInput={(e) => {
                          const value = e.target.value;
                          setIsValidCategoryName(value != "");
                          setErrors(false);
                        }}
                        onBlur={(e) => {
                          const value = e.target.value;
                          setIsValidCategoryName(value != "");
                        }}
                        placeholder={t(
                          "modalSaveCategory.nameCategoryPlaceholder"
                        )}
                        value={category.name}
                        onChange={changeValues}
                      />
                      {!isValidCategoryName && (
                        <Form.Text className="text-muted error">
                          {t("modalSaveCategory.nameCategory") +
                            " " +
                            t("global:isRequired")}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                {/* Category bank account id */}
                {sapIsActive && (
                  <Row>
                    <Col md={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          {t("modalSaveCategory.accountingAccountID")}
                        </Form.Label>
                        <Form.Control
                          id="bank_account_id"
                          type="text"
                          placeholder={t(
                            "modalSaveCategory.accountingAccountID"
                          )}
                          value={category.bank_account_id}
                          onChange={changeValues}
                          maxLength={100}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col md={12}>
                    {/* Category icon */}
                    <Form.Label>
                      {t("modalSaveCategory.categoryIcon")}
                    </Form.Label>
                    <div style={customStyles.iconsContainer}>
                      <Row>
                        {props.icons.map((icon) => {
                          return (
                            <Col
                              key={icon.id}
                              xs={1}
                              className="text-center pb-4"
                            >
                              <i
                                className={"fal " + icon.name + " fa-lg"}
                                style={customStyles.icon}
                                id={icon.id}
                              />
                              <input
                                id="icon_id"
                                type="radio"
                                value={icon.id}
                                autoFocus={category.icon_id == icon.id}
                                checked={category.icon_id == icon.id}
                                onChange={changeValues}
                                className="c-pointer"
                              />
                            </Col>
                          );
                        })}
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* Error message */}
            {errors && (
              <Row>
                <Col md={12} className="mt-3">
                  <Alert variant="warning">{errors.description}</Alert>
                </Col>
              </Row>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div>
              <Button
                variant="secondary"
                onClick={props.onHide}
                disabled={isSaving}
              >
                {t("global:buttons.cancel")}
              </Button>

              <Button
                variant="primary"
                onClick={saveCategory}
                disabled={!activeBtn || isSaving}
              >
                {isSaving ? (
                  <TextLoading
                    text={t(
                      category.id
                        ? "global:buttons.saving"
                        : "global:buttons.textAdding"
                    )}
                    variant="light"
                  />
                ) : (
                  t(category.id ? "global:buttons.save" : "global:buttons.add")
                )}
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
      {/** Modal Success */}
      {showModalSuccess && (
        <ModalSuccess
          showModalSuccess={showModalSuccess}
          title={t(
            category.id
              ? "main.changesSaved"
              : "modalSaveCategory.categoryAdded"
          )}
          message={t(
            category.id
              ? "main.changesSavedSuccess"
              : "modalSaveCategory.categoryAddedSuccess"
          )}
          fnAcceptButton={props.onHide}
        />
      )}
    </>
  );
}

ModalSaveCategory.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  schoolCycle: PropTypes.object.isRequired,
  icons: PropTypes.array.isRequired,
  updateCategories: PropTypes.func.isRequired,
  categoryToEdit: PropTypes.object,
  updateCategorySelected: PropTypes.func.isRequired,
};
